import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
// https://angular-university.io/lesson/reactive-messages-service-finished-demo
// https://angular-university.io/lesson/reactive-local-error-handling-material-dialog

@Injectable()
export class MessagesService {
    private subject = new BehaviorSubject<string[]>([]);
    errors$: Observable<string[]> = this.subject.asObservable().pipe( filter(messages => messages && messages.length > 0) );
    showErrors(...errors: string[]) { this.subject.next(errors); }
}
 
// usage
  // ctor declaration >>  , public msg: MessagesService
  // call where ever you need  this.msg.showErrors('this is from message Service');
