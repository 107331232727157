 
import { Injectable } from '@angular/core';
import { throwError  } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
@Injectable({ providedIn: 'root' })
export class CoreService {

  constructor(private http: HttpClient, private toastrService: ToastrService) { }
	success(msg: string): void { this.toastrService.success(msg); }
	info(msg: string): void { this.toastrService.info(msg); }
	warning(msg: string): void { this.toastrService.warning(msg); }
	error(msg: string): void { this.toastrService.error(msg); }

	getUrl = (path: string, applyBaseApiUrl: boolean): string => {
		const url = applyBaseApiUrl === true ? `${environment.api_url}${path}` : `${path}`;
		return url;
	}
	private formatErrors(error: any): any { return throwError(() => new Error(JSON.stringify(error).toString())); }

	get(path: string, params: HttpParams = new HttpParams(), applyBaseApiUrl = true): Observable<any> {
		const url = this.getUrl(path, applyBaseApiUrl);
		return this.http.get(url, { params }).pipe(catchError(this.formatErrors));
	}
	put(path: string, body: object = {}, applyBaseApiUrl = true): Observable<any> {
		const url = this.getUrl(path, applyBaseApiUrl);
		return this.http.put(url, body
			// , { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') }
		).pipe(catchError(this.formatErrors));
	}
	putStr(path: string, str: string, applyBaseApiUrl = true): Observable<any> {
		const url = this.getUrl(path, applyBaseApiUrl);
		return this.http.put(url, str).pipe(catchError(this.formatErrors));
	}
	post(path: string, body: object = {}, applyBaseApiUrl = true): Observable<any> {
		const url = this.getUrl(path, applyBaseApiUrl);
		return this.http.post(url, body
			// , { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'mode': 'no-cors' })}
		).pipe(catchError(this.formatErrors));
	}
	postwith(path: string, body: object = {}, applyBaseApiUrl = true, token: string): Observable<any> {
		const url = this.getUrl(path, applyBaseApiUrl);
		return this.http.post(url, body, { headers: new HttpHeaders({
			 'Content-Type': 'application/json', 'mode': 'no-cors', 'Authorization': `Bearer ${token}` 
			})}
		).pipe(catchError(this.formatErrors));
	}
	uploadFiles(path: string, body: FormData, applyBaseApiUrl = true): Observable<any> {
		const url = this.getUrl(path, applyBaseApiUrl);
		const headers = new HttpHeaders().set('UploadFile', 'true');
		return this.http.post(url, body, {
			headers: headers,
			reportProgress: true,
			observe: 'events',
		});
	}
	delete(path: string, body: object = {}, applyBaseApiUrl = true): Observable<any> {
		const httpOptions = {
			headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
			body: body
		};
		return this.http.delete(this.getUrl(path, applyBaseApiUrl), httpOptions).pipe(
			catchError(this.formatErrors));
	}
	// https://web.dev/browser-fs-access/
	downloadFile(path: string): Observable<any> {
		const url = this.getUrl(path, true);
		return this.http.get(url, { 
			observe: 'response', responseType: 'blob', reportProgress: true, 
		})
		.pipe(catchError(this.formatErrors));
	}
	downloadFiles(path: string, body: object = {}): Observable<any> {
		const url = this.getUrl(path, true);
		// const headers = new HttpHeaders().set('downloadFiles', 'true');
		return this.http.post(url, body, {
			observe: 'response', responseType: 'blob', reportProgress: true,
		}).pipe(catchError(this.formatErrors));
	}
}
