import { Injectable } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
import { IDialogData } from 'src/app/x-common/models';

@Injectable({ providedIn: 'root'})
export class DataService {
	// http://localhost/path
	// public selectedItem$: Observable<any> = new Observable<any>();
	data: IDialogData = { title: 'Dialog Title', content: '', isActions: true, rows: 10, editable: false, ddlInput: [], isIconDDL: false }
	constructor() { }
	getDialogConfig(data?: IDialogData, width?: string, height?: string, disableClose?: boolean, autoFocus?: boolean
		, restoreFocus?: boolean , minWidth?: string, maxWidth?: string, minHeight?: string , maxHeight?: string): MatDialogConfig{
		const dialogConfig = new MatDialogConfig();
		 dialogConfig.data 					= data ?? this.data;
		 dialogConfig.disableClose 	= disableClose ?? false 	; 
		 dialogConfig.autoFocus 		= autoFocus ?? true 			; 
		 dialogConfig.restoreFocus 	= restoreFocus ?? false 	; 
		 dialogConfig.width 				= width 		?? '60vw';
		 dialogConfig.minWidth 			= minWidth 	?? '10vw';
		 dialogConfig.maxWidth 			= maxWidth 	?? '80vw';
		 dialogConfig.height 				= height 		?? 'fit-content';
		 dialogConfig.minHeight 		= minHeight ?? '10vh';
		 dialogConfig.maxHeight 		= maxHeight ?? '80vh';
		 return dialogConfig;
		 
		// data.editable === true ? '60vh' : 'fit-content';
		// dialogConfig.position = { 'top': '100', left: '100' }; // by default: center
	}
}
