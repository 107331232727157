

import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'chat',
  template:  `
		<div class="chat-container mat-elevation-z7">
			<h2>How can we help you?</h2>
			<textarea placeholder="Type Your Message Here"></textarea>
			<div class="buttons-container">
				<button mat-raised-button color="primary">Send Message</button>
				<button mat-raised-button [routerLink]="[ '../', {outlets: {chat: null}}]"> Close Chat </button>
			</div>
		</div>
	
	`,
  styles: [ `
		.chat-container { position: fixed; bottom: 70px; right: 10px; height: 300px; width: 500px; display: flex; 
			flex-direction: column; background: white; border-radius: 4px; z-index: 1000; padding: 20px; font-family: Roboto; 
		} 
		.chat-container textarea { height: 200px; } 
		.chat-container .buttons-container { margin-top: 10px; display: flex; justify-content: flex-end; } 
		.chat-container .buttons-container button { margin-left: 10px; }
	
	`]
})
export class ChatComponent implements OnInit {

  constructor(private route:ActivatedRoute) { }

  ngOnInit(): void {
  }

}
