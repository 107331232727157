import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { DataService } from '../../services/data.service';
import { IDialogData } from '../../x-common/models';
import { DialogEditorComponent } from '../dialogs/dialog-editor-viewer';

@Component({
  selector: 'sixd-card',
  template:  `
		<mat-card class="mat-elevation-z4 box">
			<div *ngIf="!isHeaderIcon" >
			<!-- The mat-card-avatar directive can be applied to any element including <mat-icon> -->
				<mat-icon mat-card-avatar fontSet="material-icons-outlined" color="primary"
				[ngStyle]="{'width': size +'px', 'height': size +'px', 'font-size': '48px', 'text-align': 'center'} ">{{icon}}</mat-icon>
			</div>
			<mat-card-header>
					<mat-icon *ngIf="isHeaderIcon" style="margin-right: 10px;" fontSet="material-icons-outlined" color="primary">{{icon}}</mat-icon>
					<span> 
						<mat-card-title>{{title}}</mat-card-title> 
						<mat-card-subtitle>{{subtitle}}</mat-card-subtitle> 
					</span>
			</mat-card-header>
			
			<!-- <img mat-card-image src="./../assets/images/mountains.jpg"> -->
			<mat-card-content> {{longText}} </mat-card-content>
			<mat-card-actions>
				<button *ngIf="more" mat-button color="primary" (click)="openDialog()">Read more...</button>
    		<button *ngIf="link" mat-button color="accent">LINK</button>
			</mat-card-actions>
		</mat-card>
	`,
  styles: [ `
		/* [ngStyle]="{'transform': 'scale('+ scale +')', 'margin': '20px'}" */
		.header-image { background-size: cover; }
		section { display: table; }
		.box { display: flex; flex-direction: column; align-items: center; justify-content: center; }

	`],
   
})
export class CardIconComponent implements OnDestroy {
	@Input() longText  = '';
	@Input() title  = '';
	@Input() subtitle  = '';
	@Input() icon  = '';
	@Input() size  = 70;
	@Input() isHeaderIcon  = true;
	@Input() more  = true;
	@Input() link  = true;
	@Input() content  = '';

	// @Output() parentEmitter = new EventEmitter<number>(); 
	// @Input() id  = 0;
	// childEmitter(id: number): void { this.parentEmitter.emit(id); }
	constructor(private dialog: MatDialog, private ds: DataService) { }
	 
	openDialog(){
		if(!this.content) { return; }
		const data: IDialogData = { title: 'Content', content: this.content, editable: false, isActions: false, rows: 10 }
			const dlgcfg = this.ds.getDialogConfig(data)
			this.dialog.open(DialogEditorComponent, dlgcfg );
	}

	protected _onDestroy = new Subject<void>();
	ngOnDestroy(): any { this._onDestroy.next(); this._onDestroy.complete(); }
}
