import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common'
import { environment } from 'src/environments/environment';
import { PageResolver } from '../x-content/page.resolver';
import { MenuService } from '../services/menu.service';
import { ITitlemeta, NavItem } from '../x-common/models';
import { Meta, Title } from '@angular/platform-browser';
import { DdlService } from '../services/ddl.service';
import { delay, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { BreakpointObserver } from '@angular/cdk/layout';
import { SaCardIconComponent } from './sa-card';
import { SaHeaderImageComponent } from './sa-header-image';
import { MatDividerModule } from '@angular/material/divider';

@UntilDestroy()
@Component({
  standalone: true, imports: [ CommonModule, NgOptimizedImage, MatDividerModule, SaCardIconComponent, SaHeaderImageComponent], 
  selector: 'home-index',
  template: `
    <!-- <img class="image-home" [ngSrc]="poster" width="1" height="1" alt="home-index image" fill loading="lazy"> -->
    <div *ngIf="(resolved && (rslvr.headers$ | async)) as headers">
				<div *ngIf="headers.length>0">  <!--else defHdr -->
						<div *ngIf="headers[0].imgsrc" style="column-count: 1;">
								<sa-header-image [imgsrc]="headers[0].imgsrc!" [imght]="headers[0].imght!"
								[mdtext]="headers[0].mdtext!" [mhtext]="headers[0].mhtext!" ></sa-header-image>
						</div>
				</div>
			</div>

    <ng-template *ngIf="resolved" ngFor let-item [ngForOf]="rslvr.sections$ | async" let-i="index">
      <div class="box-grid">
        <div id="imgDiv"> <img class="picture mat-elevation-z4" [ngSrc]="path + item.imgsrc" [alt]="item.imgalt" fill loading="lazy"
        [width]="1000" [height]="1000"/> </div>
        <div id="cntDiv" class="text mat-elevation-z4"> 
          <h2 style="padding-left: 10px;">{{item.header2}}</h2> 
          <div *ngFor="let p of item.paras!"> <p [innerHTML]="p" class="text mat-elevation-z4" ></p> </div> 
        </div>
      </div>
    </ng-template> 
		
    <mat-divider style="margin: 30px 10px;"></mat-divider>

    <div *ngIf="rslvr.features$ | async as features">
      <div class="grid">
        <div *ngFor="let ft of(rslvr.features$ | async) ">
          <sa-sixd-card [title]="ft.title!" [subtitle]="ft.subtitle!" [icon]="ft.icon!"
          [isHeaderIcon]="false" [more]="ft.content!.length > 0" [link]="true" [id]="ft.id!" [content]="ft.content!"></sa-sixd-card>
        </div>
      </div>
    </div>
  `,
  styles: [`
    .image-home{ margin: 0px; width: 100%; height: 300px; } 
    .text{ max-width: 100%; border-radius: 10px; background-color: whiteSmoke; padding: 10px; }
    .picture { max-width: 100%; border-radius: 10px; }
    .grid { display: grid; 
			grid-template-columns: repeat(4, minmax(100px, 1fr)); 
			grid-auto-rows: minmax(100px, auto);  
			gap: 20px; grid-gap: 20px; }
    @media screen and (min-width: 767px){
      .box-grid { display: grid; grid-gap: 1em; gap: 1em; grid-template-columns: 300px 1fr; }
    }
    @media screen and (max-width: 767px){
      .box-grid { display: flex; flex-direction: column; }
    }
  `]
})
// notes: img w/h are mandatory and hence given nominal 1/1 for header image since class="image-home" manages internally
// for page-sections maximum w/h given as 1000/1000 and limited to the parent div class="box-grid"
export class HomeIndexComponent implements OnDestroy, OnInit, AfterViewInit {
	@Input() poster = 'https://sixdstorage.blob.core.windows.net/fileserver/WEB/web.header/imgsrc/592.webp'
	page!: NavItem;
	fullpath = '';
	resolved = false;
	path = environment.fileServer_url + '/' + environment.container + '/' + 'WEB/web.section/imgsrc/'; 

  constructor(public rslvr: PageResolver, private ms: MenuService, private title: Title, private meta: Meta
  , private ddls: DdlService, private respv: BreakpointObserver ) { }

  ngOnInit(): void {
    this.ms.routconfig$.subscribe((tf) => { 
			if(!tf) { return; }
			this.fullpath = 'home/index';
			this.page = this.ms.navItemsFlat.find(x => x.fullpath === this.fullpath)!
			const menuid = this.page ? ( this.page.id ? this.page.id : 0 )  : 0; 
			if(menuid===0) { return }
			this.ddls.metanameDDL().subscribe((metaddl) => { 
				const types = metaddl;
				this.rslvr.getBodyData(this.page).then(v =>{ 
					this.resolved = true;
					if(v[3]) {
						this.rslvr.ttlmeta$.subscribe((ttlmeta: ITitlemeta[]) => {
							if(ttlmeta.length === 0) { return }
							this.title.setTitle(ttlmeta[0]!.title);
							ttlmeta.forEach(x => {
								const name = metaddl.find(r => r.id === x.nmid)?.str;
								this.meta.addTag({ name: name ?? 'description', content: x.content })
							});
					});
					}
				});
			});
    });
  }
  ngAfterViewInit(): void {
		this.respv.observe(['(max-width: 757px)']).pipe(delay(1), untilDestroyed(this))
		.subscribe((res) => { if (res.matches) { this.swap('cntDiv') }  }); // else { this.swap('imgDiv') }
	}
  swap(secondDiv: string){ // https://ramigs.dev/blog/dynamic-order-change-html/
		const e2 = document.getElementById(secondDiv) ;
		const parent = e2!.parentNode;
		parent!.insertBefore(e2!, parent!.firstChild); // inserts e2 before firstChild
	}
  protected _onDestroy = new Subject<void>();
	ngOnDestroy(): any { this._onDestroy.next(); this._onDestroy.complete(); }
  
}
