import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, shareReplay, tap} from 'rxjs/operators';
import { StorageService } from './storage.service';
import { CoreService } from './core.service';
import { ApiUrls } from './api-urls';
import { User } from 'src/app/x-common/models';

// https://github.com/angular-university/angular-router-course/blob/e108853bae879c981f41ec0f7a9267b4175d6452/src/app/services/auth.store.ts#L12
const AUTH_DATA = "auth_data";

@Injectable({ providedIn: 'root' })
export class AuthStore {
	model: User = <User>{};
	private userSubject = new BehaviorSubject<User|null>(null);
	user$ : Observable<User|null> = this.userSubject.asObservable();
	isLoggedIn$ : Observable<boolean>;
	isLoggedOut$ : Observable<boolean>;
	 //  & typeof globalThis
	constructor(private ss: StorageService, private cs: CoreService) {
		this.isLoggedIn$ = this.user$.pipe(map(user => !!user));
		this.isLoggedOut$ = this.isLoggedIn$.pipe(map(loggedIn => !loggedIn));
		const userStr = ss.getStorage(AUTH_DATA);
		if (userStr) {
			try {
				const obj = JSON.parse(userStr); 
				if(obj.email) { this.userSubject.next(obj); } 
				else { this.userSubject.next(null); }
			} catch (error) { this.userSubject.next(null); }
		}
		else { this.userSubject.next(null); }
	}

	login(email:string, password:string): Observable<User> {
		const login: any = { email:  email, password: password };
		return this.cs.post(ApiUrls.LoginUrl, login).pipe(tap(data => {
			if (data.access_token === undefined) { 
				this.userSubject.next(null);
				this.ss.destroyItem(AUTH_DATA);
				return this.cs.warning(JSON.stringify(data).toString());
			}
			const userObj = <User>{email: email, access_token: data.access_token, isTpLogin: false, pictureUrl: '' };
			this.userSubject.next(userObj);
			this.ss.setStorage(AUTH_DATA, JSON.stringify(userObj)) 
		}), shareReplay());
	}
	logout() {
		this.userSubject.next(null);
		this.ss.destroyItem(AUTH_DATA);
	}
	
	// externalSignIn(externalAccessToken: string, provider: string, email: string ,  pictureUrl: string): void {
	// 	// this.lastData(email);
	// 	this.cs.get(ApiUrls.Account_LoginExternal + '?provider=' + provider + 
	// 	'&externalAccessToken=' + externalAccessToken
	// 	+ '&email=' + email) .subscribe( data => {
				 
	// 			if (data.access_token === undefined) { 
	// 				this.subject.next(null);
	// 				this.ss.destroyToken(AUTH_DATA);
	// 				return this.cs.warning(JSON.stringify(data).toString());
	// 			}
	// 			const user = <User>{email: email, access_token: data.access_token, isTpLogin: true, pictureUrl: pictureUrl };
	// 			this.subject.next(user);
	// 			this.ss.saveToken(AUTH_DATA, user);
				
	// 	} );
	// }
	
	googleSignIn(credential: string): Promise<boolean> {
		// this.lastData(email);
		return new Promise((resolve, reject) => {
			this.cs.get(ApiUrls.Account_googleSignin + '?credential=' + credential ) .subscribe( data => { 
					if (data.access_token === undefined) { 
						this.userSubject.next(null);
						this.ss.destroyItem(AUTH_DATA); 
						this.cs.warning(JSON.stringify(data).toString());
						reject(false);
					}
					const userObj = <User>{email: data.payload.email, access_token: data.access_token, isTpLogin: true, pictureUrl: data.payload.picture };
					this.userSubject.next(userObj);  
					this.ss.setStorage(AUTH_DATA, JSON.stringify(userObj))
					resolve(true);   
			});
		});
	}
}
 
// public class GooglePayload {
// 	public string issued_to { get; set; }
// 	public string audience { get; set; }
// 	public string user_id { get; set; }
// 	public string scope { get; set; }
// 	public int expires_in { get; set; }
// 	public string email { get; set; }
// 	public bool verified_email { get; set; }
// 	public string access_type { get; set; }
// }
