import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component,  Input, OnDestroy } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { Subject } from 'rxjs';
 

@Component({
	standalone: true,
  selector: 'sa-sixd-card', 
	imports: [ CommonModule, NgOptimizedImage, MatCardModule, MatIconModule],
  template:  `
		<mat-card class="mat-elevation-z4 box">
			<div *ngIf="!isHeaderIcon" >
			<!-- The mat-card-avatar directive can be applied to any element including <mat-icon> -->
				<mat-icon mat-card-avatar fontSet="material-icons-outlined" color="primary"
				[ngStyle]="{'width': size +'px', 'height': size +'px', 'font-size': '48px', 'text-align': 'center'} ">{{icon}}</mat-icon>
			</div>
			<mat-card-header>
					<mat-icon *ngIf="isHeaderIcon" style="margin-right: 10px;" fontSet="material-icons-outlined" color="primary">{{icon}}</mat-icon>
					<span> 
						<mat-card-title>{{title}}</mat-card-title> 
						<mat-card-subtitle>{{subtitle}}</mat-card-subtitle> 
					</span>
			</mat-card-header>
			
			<!-- <img mat-card-image src="./../assets/images/mountains.jpg"> -->
			<mat-card-content> {{longText}} </mat-card-content>
			<mat-card-actions>
				<button *ngIf="more" mat-button color="primary" >Read more...</button>
    		<button *ngIf="link" mat-button color="accent">LINK</button>
			</mat-card-actions>
		</mat-card>
	`,
  styles: [ `
		/* [ngStyle]="{'transform': 'scale('+ scale +')', 'margin': '20px'}" */
		.header-image { background-size: cover; }
		section { display: table; }
		.box { display: flex; flex-direction: column; align-items: center; justify-content: center; }

	`],
   
})
export class SaCardIconComponent implements OnDestroy {
	@Input() longText  = '';
	@Input() title  = '';
	@Input() subtitle  = '';
	@Input() icon  = '';
	@Input() size  = 70;
	@Input() isHeaderIcon  = true;
	@Input() more  = true;
	@Input() link  = true;
	@Input() content  = '';

	// @Output() parentEmitter = new EventEmitter<number>(); 
	// @Input() id  = 0;
	// childEmitter(id: number): void { this.parentEmitter.emit(id); }
	constructor( ) { }

	protected _onDestroy = new Subject<void>();
	ngOnDestroy(): any { this._onDestroy.next(); this._onDestroy.complete(); }
}
