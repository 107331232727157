import { Component, Input, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from '../../../environments/environment';

@Component({
	selector: 'sixd-float', 
	template: `
		<mat-divider></mat-divider>
		<h2 style="padding-left: 10px;">{{header2}}</h2>
		<div *ngIf="isVideo(imgsrc); else image" class="picture"> 
			<video autoplay muted loop [src]="path + imgsrc" type="video/mp4"> </video> 
		</div> 
		<ng-template #image>
			<img class="picture mat-elevation-z4" [src]="path + imgsrc" [alt]="imgalt" [ngStyle]="{'width': wImg + '%', 'height': hImg + '%'}" />  
		</ng-template>
		<div *ngFor="let p of paras"> 
			<p [innerHTML]="p" class="text mat-elevation-z4" ></p> 
			<!-- <quill-view [content]="p" [preserveWhitespace]="true" format="html"></quill-view>  -->
		</div> 
	 
	`,
	styles: [`
		.picture { float: left; max-width: 100%; border-radius: 10px; margin-right: 15px; }
		.text { font-size: 16px; padding: 10px; background-color: white; }
	`]
})
// this works like charm (<p [innerHTML]="p"></p>) than ( <quill-view [content]="p" [preserveWhitespace]="true" format="html"></quill-view> )
 
// replacing PageSectionComponent
export class SixdFloatComponent implements OnDestroy {
	@Input() imgsrc = 'images/6Dlogo.webp';
	@Input() imgalt = 'xxx company logo';
	@Input() wImg = 50;  
	@Input() hImg = 50;  
	@Input() header2 = 'xxx About Company';
	@Input() paras: string[] = [];
	path = environment.fileServer_url + '/' + environment.container + '/' + 'WEB/web.section/imgsrc/'; 
	constructor() { }
	isVideo(imgsrc: string): boolean{ return imgsrc.includes('.mp4') }
	protected _onDestroy = new Subject<void>();
	ngOnDestroy(): any { this._onDestroy.next(); this._onDestroy.complete(); }
}
