import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
	selector: 'sixd-drag',
	template: `
		<div cdkDrag cdkDragRootElement=".cdk-overlay-pane" style="display: flex;">
				<div class="sixd-drag-handle" cdkDragHandle> 
						<svg width="40px" fill="url(#grad1)" viewBox="0 0 24 24">
								<path d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"></path>
								<!-- <path d="M0 0h24v24H0z" fill="none"></path> -->
								<defs>
										<linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
												<stop offset="0%" style="stop-color:rgb(255,255,0);stop-opacity:1" />
												<stop offset="100%" style="stop-color:rgb(255,0,0);stop-opacity:1" />
										</linearGradient>
								</defs>
						</svg> 
				</div> 
				<h2 style="margin-left: 50px; margin-top: 15px;" mat-dialog-title> {{header}} </h2>
		</div>
	`,
	styles: ['.sixd-drag-handle { position: relative; top: 10px; left: 10px; cursor: move; width: 24px; height: 24px; }']
})
export class SixdDragComponent implements OnDestroy {
	constructor() { }
	@Input()  header  = '';
	protected _onDestroy = new Subject<void>();
	ngOnDestroy(): any { this._onDestroy.next(); this._onDestroy.complete(); }
}
