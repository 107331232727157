import { Injectable } from "@angular/core";
import { BehaviorSubject, catchError, Observable, map, tap, shareReplay, finalize } from "rxjs";
import { LoadingService } from "src/app/services/loading.service";
import { IFeature, IHeaders, IParas, ISection, ITitlemeta } from "src/app/x-common/models";
import { ApiUrls } from "./api-urls";
import { CoreService } from "./core.service";

// https://angular-university.io/lesson/reactive-step-by-step-implementation-of-an-angular-store-service
// https://angular-university.io/lesson/reactive-store-optimistic-data-modifications-implementation
@Injectable({ providedIn: 'root'})
export class LiveServices {
	private subjTitlemeta = new BehaviorSubject<ITitlemeta[]>([]);
	private subjSections = new BehaviorSubject<ISection[]>([]);
	private subjSections2 = new BehaviorSubject<ISection[]>([]);
	private subjParas = new BehaviorSubject<IParas[]>([]);
	private subjFeatures = new BehaviorSubject<IFeature[]>([]);
	private subjHeaders = new BehaviorSubject<IHeaders[]>([]);
	// obsTitlemeta$: Observable<ITitlemeta[]> = this.subjTitlemeta.asObservable();
	 
	constructor(private ldng: LoadingService, private cs: CoreService) { }  

	titlemeta(menuid: number): Observable<ITitlemeta[]> {
		const load$ = this.cs.get(ApiUrls.web_titlemeta + '?mnid=' + menuid).pipe(
			map((r: any) => {
				if (r.Item2.id < 0) { this.cs.warning('Error!'); 
				return this.subjTitlemeta.next([]);  }
				return r.Item1;
			}),
			finalize( () => {}), // console.log('LiveServices.titlemeta() finalize called') 
			catchError(err => {  console.log(err); 
				console.log(err); this.subjTitlemeta.next([]); return []; }),
			tap((Item1: ITitlemeta[]) => {
				if (Item1.length > 0) { return  this.subjTitlemeta.next(Item1); } 
				else { return this.subjTitlemeta.next([]); } 
		}), shareReplay());
		this.ldng.showLoaderUntilCompleted(load$).subscribe();
		return this.subjTitlemeta;
	}
	
	sections(menuid: number): Observable<ISection[]> {
		const load$ = this.cs.get(ApiUrls.web_sections + '?mnid=' + menuid).pipe(
			map((r: any) => {
				if (r.Item2.id < 0) {  this.cs.warning('Error!'); return this.subjSections.next([]);  }
				return r.Item1;
			}),
			finalize( () => {} ), // console.log('LiveServices.sections() finalize called')
			catchError(err => { // this.cs.warning('See console for Error!');  
				console.log(err); this.subjSections.next([]); return []; }),
			tap((Item1: ISection[]) => {
				if (Item1.length > 0) { return this.subjSections.next(Item1); } 
				else { return this.subjSections.next([]); } 
			}), shareReplay());
		this.ldng.showLoaderUntilCompleted(load$).subscribe();
		return this.subjSections;
	}
	paras(csvids: string): Observable<IParas[]> { 
		const load$ = this.cs.get(ApiUrls.web_paras + '?csvids=' + csvids).pipe(
			map((r: any) => {
				if (r.Item2.id < 0) {  this.cs.warning('Error = ' + r.Item2.str); return this.subjParas.next([]); }
				return r.Item1;
			}),
			catchError(err => {  // this.cs.warning('See console for Error!');  
			console.log(err); this.subjParas.next([]); return []; }),
			finalize( () => {}), // console.log('LiveServices.paras() finalize called') 
			tap((Item1: any) => {
				if (Item1.length > 0) { return  this.subjParas.next(Item1); } 
				else {    
				return this.subjParas.next([]); } 
			}), shareReplay());
		this.ldng.showLoaderUntilCompleted(load$).subscribe();
		return this.subjParas;
	}
	sections2(menuid: number): Observable<ISection[]> {
		const load$ = this.cs.get(ApiUrls.web_sections2 + '?mnid=' + menuid).pipe(
			map((r: any) => {
				if (r.Item2.id < 0) {  this.cs.warning('Error = ' + r.Item2.str); return this.subjSections2.next([]); }
				return r.Item1;
			}),
			catchError(err => { console.log(err); this.subjSections2.next([]); return []; }),// this.cs.warning('See console for Error!');  
			finalize( () => {}), // console.log('LiveServices.sections2() finalize called') 
			tap((Item1: ISection[]) => {
				if (Item1.length > 0) { return this.subjSections2.next(Item1); } 
				else { return this.subjSections2.next([]); } 
			}), shareReplay());
		this.ldng.showLoaderUntilCompleted(load$).subscribe();
		return this.subjSections2;
	}
	paras2(menuid: number): Observable<IParas[]> { 
		const load$ = this.cs.get(ApiUrls.web_paras2 + '?menuid=' + menuid).pipe(
			map((r: any) => {
				if (r.Item2.id < 0) {  this.cs.warning('Error = ' + r.Item2.str);  
				return this.subjParas.next([]);  }
				return r.Item1;
			}),
			catchError(err => { console.log(err); this.subjParas.next([]); return []; }),// this.cs.warning('See console for Error!');  
			finalize( () => {} ), // console.log('LiveServices.paras2() finalize called')
			tap((Item1: any) => {
				if (Item1.length > 0) { return  this.subjParas.next(Item1); } 
				else {    
				return this.subjParas.next([]); } 
			}), shareReplay());
		this.ldng.showLoaderUntilCompleted(load$).subscribe();
		return this.subjParas;
	}
	features(menuid: number): Observable<IFeature[]> { 
		const load$ = this.cs.get(ApiUrls.web_features + '?mnid=' + menuid).pipe(
			map((r: any) => {
				if (r.Item2.id < 0) {  this.cs.warning('Error = ' + r.Item2.str);  
				return this.subjFeatures.next([]);  }
				return r.Item1;
			}),
			catchError(err => { console.log(err); this.subjFeatures.next([]); return []; }),// this.cs.warning('See console for Error!');  
			finalize(() => {} ), //  () => console.log('LiveServices.paras2() features called')
			tap((Item1: any) => {
				if (Item1.length > 0) { return  this.subjFeatures.next(Item1); } 
				else {    
				return this.subjFeatures.next([]); } 
			}), shareReplay());
		this.ldng.showLoaderUntilCompleted(load$).subscribe();
		return this.subjFeatures;
	}
	headers(menuid: number): Observable<IHeaders[]> { 
		const load$ = this.cs.get(ApiUrls.web_headers + '?mnid=' + menuid).pipe(
			map((r: any) => {
				if (r.Item2.id < 0) { this.cs.warning('Error = ' + r.Item2.str); return this.subjHeaders.next([]); }
				else return r.Item1;
			}),
			catchError(err => { this.subjHeaders.next([]); console.log(err); return []; }), // this.cs.warning('See console for Error!'); 
			finalize( () => {} ), // console.log('LiveServices.paras2() headers called')
			tap((Item1: IHeaders[]) => {
				if (Item1.length > 0) { return  this.subjHeaders.next(Item1); } 
				else { return this.subjHeaders.next([]); } 
			}), 
			shareReplay()
		);
		this.ldng.showLoaderUntilCompleted(load$).subscribe();
		return this.subjHeaders;
	}
	
}

