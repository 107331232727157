import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, Observable } from "rxjs";
import { ApiUrls } from "./api-urls";
import { CoreService } from "./core.service";

@Injectable()
export class NewsService{
	constructor(private cs: CoreService) { } 
	addsubscriber(email: string): Observable<string>{
		return this.cs.get(ApiUrls.web_mailid + '?email=' + email).pipe(map(data => { debugger;
			if (data) { return data!.str; } else { return 0 }
		}));
		// return this.http.post('api/notifications', sub);
	}

	send(){

	}
}
