import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject,of} from 'rxjs';
import {concatMap, finalize, tap} from 'rxjs/operators';
// https://angular-university.io/lesson/reactive-loading-indicator-finished
@Injectable() // this is NOT global singleton but used at multiple locations, so provided in root is NOT set
export class LoadingService {

  private loadingSubject = new BehaviorSubject<boolean>(false);

  loading$: Observable<boolean> = this.loadingSubject.asObservable();

  constructor() { } // console.log("Loading service created ..."); 

  showLoaderUntilCompleted<T>(obs$: Observable<T>): Observable<T> {
    return of(null).pipe( 
      tap(() => this.loadingOn()), 
      concatMap(() => obs$), 
      finalize(() => this.loadingOff()) 
    ); 
  }
  
  loadingOn(): void { this.loadingSubject.next(true); }

  loadingOff(): void { this.loadingSubject.next(false); }

}

// usage as below
  // EITHER THIS 
    // const titleMedia$ = this.cs.get(ApiUrls.web_titlemeta + '?mnid=' + this.menuid).pipe(map(r => r));
    // this.loading.showLoaderUntilCompleted<ITitlemeta[]>(titleMedia$).subscribe();
  // OR THIS 
    // this.ls.loadingOn();
    // this.cs.get(ApiUrls.web .... ).subscribe(data => { ....
		// this.ls.loadingOff(); // setTimeout(() => { this.ls.loadingOff(); }, 2000);
