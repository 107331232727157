import { Component, Inject, Input, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Subject } from "rxjs";
import { IDialogData } from "src/app/x-common/models";
// https://blog.angular-university.io/angular-material-dialog/
@Component({
	selector: 'dialog-editor-viewer',
	template: `
		<sixd-drag *ngIf="isActions" [header] = "data.title!">  </sixd-drag>
		<mat-dialog-content *ngIf="editable===true; else viewer">
			<div  [formGroup]="form" >
				<mat-form-field class="this-only" style="width: '100%'; margin: 0px;"> 
				<!-- <textarea [ngStyle]="{height: height}" matInput placeholder="Description" 
				formControlName="desc" [rows]="rows"></textarea> -->
				<mat-quill [style]="{height: height, width: '100%'}" matInput formControlName="contentFld" 
            name="myckeditor" required></mat-quill>
				</mat-form-field>
			</div>
		</mat-dialog-content>
		<mat-dialog-content>	
			<ng-template #viewer>
				<quill-view [content]="data.content" format="html"></quill-view>
			</ng-template>
		</mat-dialog-content>

		<mat-dialog-actions *ngIf="isActions" align="end">
			<button mat-icon-button color="primary" (click)="save()" ><mat-icon>save</mat-icon></button>
			<button mat-icon-button color="warn" (click)="close()"><mat-icon>close</mat-icon></button>
		</mat-dialog-actions>
	`, styles: [`
			::ng-deep .this-only .mat-form-field-underline{ display: none; }
		`]
})
// FOR USING CDK EDIT; the following MUST BE STRICLTY FOLLOWED
// ANGULAR >> The content must be passed in BODY in POST or PUT request ONLY, [content]
//      IF the content is small then use the ESCAPE AS >>> escape(model.header) 
// WEBAPI >> parameter MUST be in [FromBody]string[] content and passed as content[0] to the DAL method
export class DialogEditorComponent implements OnInit, OnDestroy {
	form!: FormGroup;
	isActions = true;
	@Input() editable = true;
	rows = 2; height = '40px'; // assuming each row height: 30px
	protected _onDestroy = new Subject<void>();
	constructor( private fb: FormBuilder, private dialogRef: MatDialogRef<DialogEditorComponent>
		, @Inject(MAT_DIALOG_DATA) public data: IDialogData) {
			this.isActions = this.data.isActions ?? true;
			this.rows = this.data.rows ?? 2; 
			this.height = (this.rows * 30).toString() + 'px'; 
			this.editable = this.data.editable ?? true;
	}
	ngOnInit() {
		this.form = this.fb.group({ contentFld: [this.data.content, []], });
	}

	save() { this.dialogRef.close(this.form.value); } 

	close() { this.dialogRef.close(); }
	
	ngOnDestroy(): any { this._onDestroy.next(); this._onDestroy.complete(); }
}


/* USAGE 
<quill-view [content]="row[col.field]" matInput [style.width]="col.width+'px'" [id]="i + col.field"
									(dblclick)="quillViewer(row[col.field])" format="html"></quill-view>
import { DialogPopComponent } from '../dialogs/dialog-editor-viewer';
constructor ( ..., public ds: DataService, public dialog: MatDialog){ }
...FOR VIEWING
	quillViewer(content: any): void{
		const data: IDialogData = { title: 'Content', content: content, editable: false, isActions: false, rows: 10 }
		const dlgcfg = this.ds.getDialogConfig(data)
		this.dialog.open(DialogPopComponent, dlgcfg );
	}
...FOR EDITING
	editPara(row: IParas): Promise<boolean> {
		return new Promise((resolve, reject) => {
			const data: IDialogData = { title: 'Edit Page Paragraph', content: row.para, editable: true, isActions: true, rows: 10 }
			const dlgcfg = this.ds.getDialogConfig(data)
			const dialogRef = this.dialog.open(DialogPopComponent, dlgcfg );
			dialogRef.afterClosed().subscribe((formValue: any) => { 
				if (formValue.contentFld) {
					this.cs.put(ApiUrls.web_paraEdit + '?id=' +row.id + '&scid=' + row.scid
						, [formValue.contentFld] ).subscribe(data => { if (data.id < 0) { this.cs.warning(data.str) ; reject(false);}
						else { this.cs.success(data.str); resolve(true); } // this.paras(row.scid);
					});
				} else { reject(false); }
			});
		});
*/
