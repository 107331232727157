import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
 
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { SixdDdlComponent } from './sixd/sixd-ddl';
import { SafePipe, SafeUrlPipe, XPad } from './directives/pipes';
import { SixdFormDdlComponent } from './sixd/sixd-form-ddl';
import { SixdTxtComponent } from './sixd/sixd-txt';
import { SixdFormComponent } from './sixd/sixd-form';
import { SixdDragComponent } from './sixd/sixd-drag';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { DmsFileViewComponent } from './files/dms-file-view.component';
import { ImageViewerComponent } from './files/image-viewer.component';
import { DialogEditorComponent } from './dialogs/dialog-editor-viewer';
// import { ShowRichTextComponent } from './dialogs/xxtext-viewer';
import { QuillModule } from 'ngx-quill';
import { MatQuillModule } from '../admin/mat-quill/mat-quill-module';
import { RichTextEditorComponent } from './dialogs/rich-text-editor';
import { DialogDdlComponent } from './dialogs/dialog-ddl';
import { MaterialExampleModule } from '../material.module';
import { FileUploadComponent } from './files/FileUpload.component';
import { FileInputConfig, MaterialFileInputModule, NGX_MAT_FILE_INPUT_CONFIG} from 'ngx-material-file-input';
import { FormTemplateComponent } from './templates/form-template';
import { ResposiveDesignComponent } from './templates/responsive';
import { HtmlToPlaintextPipe, PageSectionComponent } from './templates/xxpage-section';
import { CardIconComponent } from './sixd/sixd-card';
// import { CardLayoutTemplateComponent } from './sixd/sixd-card-layout';
import { TableClientSideComponent } from './templates/table-client-side';
import { TableServerSideComponent } from './templates/table-server-side';
import { TreeViewComponent } from './templates/tree-view';
import { DotCommandsComponent } from './templates/dot-commands';
import { GotoComponent, HeaderImageComponent } from './templates/header-image';
// import { ThreeHeaderComponent } from './templates/header-3d';
// import { SixdVideoComponent } from './sixd/sixd-video';
import { SixdDdlIconComponent } from './sixd/sixd-ddl-icon';
import { SixdFormDdlIconComponent } from './sixd/sixd-form-ddl-icon';
import { DialogStringComponent } from './dialogs/dialog-string';
// import { VimeModule } from '@vime/angular';
import { YouTubePlayerModule } from '@angular/youtube-player'; 
import { SixdYouTubeComponent } from './sixd/sixd-youtube1';
import { YouTubeApiComponent } from './sixd/sixd-youtube';
import { BGComponent } from './templates/bg-video';
import { SixdFloatComponent } from './sixd/sixd-float';

export const config: FileInputConfig = { sizeUnit: 'Octet' };

@NgModule({
	declarations: [
		  SixdDdlComponent, XPad, SixdFormDdlComponent, SixdTxtComponent, SixdFormComponent
		, SixdDragComponent, DmsFileViewComponent , FileUploadComponent , ImageViewerComponent 
		, DialogEditorComponent , SafeUrlPipe , SafePipe, RichTextEditorComponent
		, DialogDdlComponent , PageSectionComponent, TableServerSideComponent, TableClientSideComponent
		, CardIconComponent , FormTemplateComponent , ResposiveDesignComponent // , SixdVideoComponent
		, HtmlToPlaintextPipe , TableServerSideComponent, TableClientSideComponent, TreeViewComponent, DotCommandsComponent
		, HeaderImageComponent, GotoComponent, SixdDdlIconComponent, SixdFormDdlIconComponent
		, DialogStringComponent, SixdYouTubeComponent, YouTubeApiComponent, BGComponent, SixdFloatComponent
		// , ThreeHeaderComponent
	],
	imports: [
		CommonModule, MaterialExampleModule
		, FormsModule, ReactiveFormsModule, NgxMatSelectSearchModule
		, QuillModule.forRoot({ suppressGlobalRegisterWarning: true })
		, MatQuillModule, MaterialFileInputModule , YouTubePlayerModule // , VimeModule
	], 
	exports: [
		PageSectionComponent, HtmlToPlaintextPipe , TableServerSideComponent, TableClientSideComponent 
		, SixdDdlComponent, XPad, SixdFormDdlComponent, SixdTxtComponent, SixdFormComponent
		, SixdDragComponent, SafeUrlPipe, SafePipe, CardIconComponent 
		, TreeViewComponent, DotCommandsComponent, HeaderImageComponent, GotoComponent, DialogEditorComponent
		, SixdDdlIconComponent, SixdFormDdlIconComponent, DialogStringComponent, SixdYouTubeComponent
		, YouTubeApiComponent, BGComponent, SixdFloatComponent // , ThreeHeaderComponent , SixdVideoComponent
	], 
	providers: [
		{ provide: NGX_MAT_FILE_INPUT_CONFIG, useValue: config }
	],
	
})
export class SharedModule { }
