

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuMdInputComponent, InputRefDirective} from './sixd-md-input';
import { MaterialExampleModule } from 'src/app/material.module';
 

@NgModule({
	declarations: [InputRefDirective, AuMdInputComponent],
	imports: [ CommonModule, MaterialExampleModule ],
	exports: [ AuMdInputComponent, InputRefDirective]
})
export class AuInputModule { }
