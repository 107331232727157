import { Injectable } from '@angular/core';
import { GlobalService } from './global.service';

@Injectable({ providedIn: 'root' })
export class StorageService {

  // READ THIS https://github.com/angular/universal/blob/main/docs/gotchas.md
  constructor(private gbl: GlobalService ) { } // private gbl: GlobalService

  setStorage(key: string, token: any): void {
    this.gbl.getWindow()!.localStorage.setItem(key, token);
  }

  getStorage(key: string): string | null {
    // const ss = this.gbl.getWindow()!.localStorage;
    const value = localStorage.getItem(key);
    return !!value ? value : null;
    // return this.gbl.getWindow() ? this.gbl.getWindow()!.localStorage.getItem(key)! : null;
  }

  destroyItem(key: string): void {
    this.gbl.getWindow()!.localStorage.removeItem(key);
  }
}
/*
      import { CoreService, StorageService } from 'app/main/core';
      ctor >> private ss: StorageService
      set  >> get  >>  
        onChange(value): void { this.XXGRIDCALL(); this.ss.saveToken('Xms.lastXXX', value); }
        if (this.ss.getToken('Xms.lastXXX')) { this.xxID = +this.ss.getToken('Xms.lastXXX');  this.onChange(this.xxID); } 

*/ 
