 
import { Component, ElementRef, OnDestroy, OnInit, ViewChild, AfterViewInit, ViewEncapsulation, NgZone } from '@angular/core';
import { BehaviorSubject, catchError, delay, filter, finalize, forkJoin, fromEvent, map, Observable, of, Subject, Subscription } from 'rxjs';
import { NavItem, User } from './x-common/models';
import { MenuService } from './services/menu.service';
import { NavService } from './services/nav.service';
import { CoreService } from './services/core.service';
import { SwPush, SwUpdate, VersionEvent, VersionReadyEvent } from '@angular/service-worker';
import { LoadingService } from './services/loading.service';
import { MessagesService } from './services/messages.service';
import { AuthStore } from './services/auth.store';
import { NewsService } from './services/news.service';
import { NavigationEnd, Router } from '@angular/router';
import { AnyPageComponent } from './x-content/any-page.component';
import { PageNotFoundComponent } from './x-common/four-o-four';
import { MatSidenav } from '@angular/material/sidenav';
import { BreakpointObserver } from '@angular/cdk/layout';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { GlobalService } from './services/global.service';
import { Config, ConfigService } from './services/config.service ';

// https://codinglatte.com/posts/angular/responsive-navbar-angular-flex-layout/ 
// https://stackblitz.com/edit/dynamic-nested-sidenav-menu-duubjg?file=app%2Fapp.component.ts
// https://stackblitz.com/edit/dynamic-nested-topnav-menu-wzrzea?file=app%2Fapp.component.ts

@UntilDestroy()
@Component({
	selector: 'app-root',
	template: `
		<div style="height: 100vh;">
			<mat-toolbar color="primary">

				<mat-toolbar-row>
					<button id="menu" title="navigation" mat-icon-button (click)="appDrawer.toggle()"> 
						<!-- <mat-icon>menu</mat-icon>  -->
						<mat-icon *ngIf="!appDrawer.opened"> menu </mat-icon>
    				<mat-icon *ngIf="appDrawer.opened"> close </mat-icon>
					</button>
						<!-- fxShow="true" fxHide.gt-sm -->
					<span>{{title}}</span> <span class="my-spacer"></span>
					<!-- fxShow="true" fxHide.lt-md="true"  -->
					<div *ngIf="!ltmd && navNested.length>0" color="accent">
						<span *ngFor="let item of navNested">
							<span *ngIf="item.children && item.children.length > 0">
								<button class="nav-item" mat-button [matMenuTriggerFor]="menu.childMenu" 
									[disabled]="item.disabled"><mat-icon>{{item.iconName}}</mat-icon> {{item.displayName}} </button>
								<top-menu-item #menu [items]="item.children"></top-menu-item>
							</span>
							<span *ngIf="!item.children || item.children.length === 0" >
								<!-- <button mat-button [routerLink]="item.fullpath"> {{item.displayName}} </button> -->
								<a mat-button class="nav-item" [href]="'#/'+ item.fullpath" > 
									<mat-icon>{{item.iconName}}</mat-icon> {{item.displayName}} </a>
							</span>
						</span>
					</div>
					
					<!-- <a class="nav-item" mat-button (click)="notifySubs()" > 
						<mat-icon >notification_important</mat-icon> </a>

					<a mat-button *ngIf="auth.isLoggedIn$ | async" [routerLink]="[{outlets: {chat: ['helpdesk-chat']}}]">
						<mat-icon>chat</mat-icon>
					</a> -->
					<a class="nav-item" mat-button routerLink="account/login" *ngIf="auth.isLoggedOut$ | async">
						<mat-icon>account_circle</mat-icon>
						<span>Login</span>
					</a>
					<a class="nav-item" mat-button (click)="logout()" *ngIf="auth.isLoggedIn$ | async">
						<mat-icon>exit_to_app</mat-icon>
						<span>Logout</span>
					</a>
				</mat-toolbar-row>
			</mat-toolbar>
			
			<!-- <div> <h1>Application version 8</h1> </div> -->
			<div class="version-position" *ngIf="modalVersion">
					<button mat-button type="button" color="primary" (click)="closeVersion()">Cancel</button> 
					<a href="" (click)="updateVersion()">Update now</a>
			</div>

			

			<!-- <div><button mat-raised-button (click)="notifySubs()">Notifications</button></div> -->
			<mat-sidenav-container >
				<mat-sidenav #appDrawer class="mat-elevation-z8">
					<img class="avatar mat-elevation-z8" src="assets/images/me.webp" />
					<h4 class="name">Yudhishtirudu Gaddipati</h4>
					<p class="email">{{loginEmail$ | async}}</p>
					<mat-divider></mat-divider>
					<mat-nav-list *ngIf="navNested.length>0">
						<side-menu-item *ngFor="let item of navNested" [item]="item"></side-menu-item>
					</mat-nav-list>
					<mat-divider></mat-divider>
				</mat-sidenav>
				<mat-sidenav-content>
					<div class="mat-elevation-z8">
						<messages></messages>
						<loading [detectRoutingOngoing]="true"></loading>
						<router-outlet></router-outlet>
					</div>
				</mat-sidenav-content>
				<!-- <ngx-spinner></ngx-spinner> -->
			</mat-sidenav-container>
			<!-- <app-online-status [onlineStatusMessage]="connectionStatusMessage" 
				[onlineStatus]="connectionStatus"> </app-online-status> -->
			<router-outlet name="chat"></router-outlet>
	</div>
	`
	, encapsulation: ViewEncapsulation.None
	, styles: [`
				.nav-item { color: #fff !important; font-size: 16px !important; }
				/* .mat-icon{transform: scale(0.75); } */
				mat-sidenav-container { height: calc(100vh - 65px); }
				mat-sidenav { margin: 1em; width: 200px; border-radius: 10px; padding-left: 1em; font-size: 1em; } /*text-align: center;*/
				.avatar { margin-top: 16px; width: 100px; height: 100px; border-radius: 50%; }
				.name { margin-top: 8px; font-weight: normal; }
				.email { margin-top: 2px; font-size: 0.7rem; color: grey; }
				mat-divider { margin-top: 16px; margin-bottom: 16px; background-color: rgba(255, 255, 255, 0.5); }
				 
	 `]
})
 
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
		protected _onDestroy = new Subject<void>();
		navNested: NavItem[] = [];
		navFlat: NavItem[] = [];
		title = '6D Proptech Pte Ltd';
		// email = 'g.yudhi@gmail.com'
		private loginEmail = new BehaviorSubject<string>('');
  	loginEmail$: Observable<string> = this.loginEmail.asObservable();
		// for fresh keys run this at console >> web-push generate-vapid-keys --json
		readonly VAPID_PUBLICC_KEY = "BGZjRJ-tcgtXc8njG-ZbTw8cfMyvBb-03-7Zj0lfb8b8XAnVNCRJ-NjnE61Ev21Pa64wv6V2dbOyI0Y51M27NQg"
		@ViewChild(MatSidenav) appDrawer!: MatSidenav // ElementRef | undefined;
		loading: boolean = true;  // https://web.dev/route-level-code-splitting-in-angular/
		
		// https://angular-offline-online-detection.stackblitz.io
		onlineEvent!: Observable<Event>;
		offlineEvent!: Observable<Event>;
		subscriptions: Subscription[] = [];
		// connectionStatusMessage!: string;
		connectionStatus!: string;
		isOnline!: boolean; modalVersion!: boolean;
		loginurl = '/account/login';
		ltmd = false; // lt.md = less than mid-sized screen 40em or 767px
		
		config!: { host: string; email: string; }
		
		constructor( private navService: NavService, private ldng: LoadingService, private msgs: MessagesService
			, private ms: MenuService, private cs: CoreService, private swUpdate: SwUpdate, public auth: AuthStore
			, private swPush: SwPush, private news: NewsService, private router: Router
			, private brptObserver: BreakpointObserver, private _ngZone: NgZone, private gbl: GlobalService
			, private cfg: ConfigService) {
			this.isOnline = false;
    	this.modalVersion = false; 
			
		}
		ngOnInit(): void { 
			this.checkVersion(); 
			this.onlineEvent = fromEvent(this.gbl.getWindow()!, 'online');
			this.offlineEvent = fromEvent(this.gbl.getWindow()!, 'offline');
			this.subscriptions.push(this.onlineEvent.subscribe(e => {
				this.connectionStatus = 'online';// this.connectionStatusMessage = 'Back to online';
				console.log('Online...'); // this.cs.success(this.connectionStatus);
			}));
			this.subscriptions.push(this.offlineEvent.subscribe(e => {
				this.connectionStatus = 'offline'; //  this.connectionStatusMessage = 'Connection lost! You are not connected to internet';
				console.log('Offline...'); // this.cs.error(this.connectionStatus);
			}));
		 
			this.ldng.loadingOn(); 
			this.cfg.getConfig().subscribe((data: Config[]) =>  {
				this.config = data.find(x => x.host === window.location.hostname)!;
				this.ms.prodEmail(this.config).pipe(
					catchError((err) => { console.log('Error: ', err);  return of(''); }),
					finalize(() =>{ this.ldng.loadingOff(); })
				).subscribe((email) => {
					this.loginEmail.next(email);
					this.ms.loadNavMenu(email).subscribe({
						next: (r) => { 
							this.navFlat = this.ms.navItemsFlat 
							this.navNested = this.ms.getNestedMenu(this.navFlat);
							this.populateRoutes(); 
							setTimeout(() => { 
								this.ldng.loadingOff();
								if(!this.gbl.getWindow()!.navigator.onLine){ console.log('You are offline'); }}, 4000); // this.cs.error('You are offline');
						} , error: (e) => {}, complete: () =>{}
					})
				})
			});
			
			this.detectDivChanges();
		}
		showConfig() {
			this.cfg.getConfig().subscribe((data: Config[]) =>  {
				this.config = data.find(x => x.host === window.location.hostname)!;
			});
		}
		populateRoutes() {
			const leafNodes = this.ms.getLeafNodes(this.navNested); 
			const leafIDs = leafNodes.map(x => x.id); 
			this.navFlat.forEach(x => x.isLeaf = leafIDs.includes(x.id))
			const leaves = this.navFlat.filter( x => x.isLeaf === true); 
			// this is hard-coded in app-module else dynamic injection would NOT work and lead to ERRORs
			let emptyRedirect = leaves.find(f => f.fullpath === 'home/index'); 
			for (let nav of leaves) {
				if(!nav.fullpath?.startsWith('admin')) { // admin not allowed since already hard-code pushed
					if( nav.fullpath !== emptyRedirect!.fullpath) { // since redirect item already pushed
						let route = { path: nav.fullpath, component: AnyPageComponent };
						this.router.config.push(route);
					}
				}
			};  
			this.router.config.push({ path: '**', component: PageNotFoundComponent });
			this.ms.setRoutconfig(true);
			// console.log('router.config: ', this.router.config);
		}
	 
		notifySubs(){ // works in production only
			//web-push >> https://angular-university.io/lesson/vapid-key-generation
			// this is global and comes from google firestore to every app-user with endpoint https://fcm.googleapis.com/fcm/send/...
			// https://angular-university.io/lesson/push-notifications-request
			this.swPush.requestSubscription({ 
				serverPublicKey: this.VAPID_PUBLICC_KEY
			}).then(value => {
				console.log('notification subscription:', value)
				if (this.loginEmail.getValue()) {
					this.news.addsubscriber(this.loginEmail.getValue()).subscribe( (val) =>{ 
					// console.log('sent news to serve', val)
					this.msgs.showErrors('notification push to server: ' + val);
				});
				} // , err => console.log('error: ', err)
			})
			.catch(err => console.error('notification error: ', err))
		}
		ngAfterViewInit() { // console.log(this.gbl.getWindow());
			this.navService.appDrawer = this.appDrawer;
			// https://github.com/thisiszoaib/angular-responsive-sidebar
			this.brptObserver.observe(['(max-width: 757px)']).pipe(delay(1), untilDestroyed(this))
      .subscribe((res) => {
        if (res.matches) { this.appDrawer.mode = 'over'; this.ltmd = true; }  // this.appDrawer.close();
				else { this.appDrawer.mode = 'side'; this.ltmd = false; } // this.appDrawer.open();
      });
    	this.router.events .pipe( untilDestroyed(this), filter((e) => e instanceof NavigationEnd) )
      	.subscribe(() => { if (this.appDrawer.mode === 'over') {  } // this.appDrawer.close();
      });
		}
		// private updateOnlineStatus(): void { this.isOnline = this.gbl.getWindow()!.navigator.onLine; console.info(`isOnline=[${this.isOnline}]`); }
		updateVersion(): void { this.modalVersion = false; this.gbl.getWindow()!.location.reload(); }
		closeVersion(): void { this.modalVersion = false; }

		checkVersion(): void {
			// https://angular-university.io/lesson/angular-service-worker-versioning
			// https://hackernoon.com/building-progressive-web-application-pwa-with-angular
			if (this.swUpdate.isEnabled) {
				this.swUpdate.versionUpdates.subscribe(evt => { // console.log(evt);
					if (evt.type ==='VERSION_READY') {
						this.modalVersion = true;
						this.msgs.showErrors('A new version of this app is available.');
				}});
			}
		}

		logout() { 
			this.auth.logout(); 
			this._ngZone.run(() => { this.router.navigate([this.loginurl]); });  
		}

		ngOnDestroy(): any { 
			this._onDestroy.next(); this._onDestroy.complete(); 
			this.subscriptions.forEach(subscription => subscription.unsubscribe()); 
		}
		detectDivChanges() {
			// const div = document.querySelector('.mat-sidenav-container')!;
			// const config = { attributes: true, childList: true, subtree: true };
			// const observer = new MutationObserver((_mutation) => {
			// 	// console.log("it works. enjoy :)");
			// })
			// observer.observe(div, config);
		}
}
  
// if(!emptyRedirect?.fullpath) { emptyRedirect = leaves[0]; }
// this.router.config.unshift({ path: emptyRedirect.fullpath, component: AnyPageComponent });
// this.router.config.unshift({ path: '', redirectTo: emptyRedirect.fullpath, pathMatch: 'full'});
