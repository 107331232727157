import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, finalize, map, Observable, of, shareReplay, tap } from 'rxjs';
import { CoreService } from './core.service';
import { ApiUrls } from './api-urls';
import { IintString, NavItem, User } from 'src/app/x-common/models';
import { PageParams } from '../shared/templates/table-model';
import { AuthStore } from './auth.store';
import { environment } from 'src/environments/environment';
import { ItemDataSource } from '../admin/nav-datasource';
import { LoadingService } from './loading.service';
import { Config } from './config.service ';

@Injectable({ providedIn: 'root' })
export class MenuService {
	mlid: number = 0;
	// navItemsNested: NavItem[] = [];
	navItemsFlat: NavItem[] = [];
	totalNavitems: number = 0;
	private email = new BehaviorSubject<string>('');
  email$: Observable<string> = this.email.asObservable();

	private error = new BehaviorSubject<string>('');
  error$: Observable<string> = this.error.asObservable();

	private routconfig = new BehaviorSubject<boolean>(false);
  routconfig$: Observable<boolean> = this.routconfig.asObservable();

	constructor( private cs: CoreService, private auth: AuthStore, private ldng: LoadingService) { }
	
	setRoutconfig(tf: boolean) { this.routconfig.next(tf); }

	loadNavMenu(email: string): Observable<NavItem[]> { 
		const load$ = this.cs.get(ApiUrls.web_navitems + '?email=' + email).pipe(
			map((r: any) => {
				if (r.Item2.id < 0) { this.cs.warning('Error = ' + r.Item2.str); this.navItemsFlat = [];  }
				return r.Item1;
			}),
			catchError(err => { this.navItemsFlat = []; console.log(err); this.navItemsFlat = []; return []; }), //  this.cs.warning('See console for Error!'); 
			finalize( () => [] ), // console.log('MenuService.loadNavMenu() finalize called')
			tap((Item1: any) => {
				if (Item1.length > 0) { this.navItemsFlat = Item1; } 
				else { this.navItemsFlat = [];  } 
			}), shareReplay());
		this.ldng.showLoaderUntilCompleted(load$).subscribe();
		return load$;
	}
	// webNavitems(email: string, reload = false): Observable<NavItem[]> { 
	// 	if (!reload && this.navItemsNested.length > 0){ return of(this.navItemsNested); } 
	// 	else {
	// 		return this.cs.get(ApiUrls.web_navitems + '?email=' + email).pipe(map(data => {
	// 			if (data.Item1.length > 0 ) {  
	// 				this.navItemsFlat = data.Item1;
	// 				this.navItemsNested = this.getNestedMenu(data.Item1); 
	// 				return this.navItemsNested;
	// 			} else { return []}
	// 		}), shareReplay());
	// 	}
	// }
	devEmail(): Observable<string> {  
		return this.email.getValue() ? this.email$ : 
		this.auth.user$.pipe(map(user => user?.email ? user?.email : ''))
	}
	prodEmail(cfg: Config): Observable<string> {  // environment.production  window.location.host
		// let host = window.location.host; 
		// if(window.location.host === 'localhost:4200') { 
		// 	cfg.host = 'www.6dproptech.com'
		// 	cfg.email = 'g.yudhi@gmail.com'
		// }
		// return this.email.getValue() ? this.email$ : this.weblink(cfg.host).pipe(map( res => res.id > 0 ? res.str : '' ))
		if(!this.email.getValue()) { this.email.next(cfg.email); }
		return this.email$ 
	}
	// weblink(host: string): Observable<IintString> { 
	// 	return this.cs.get(ApiUrls.web_link + '?app_path=' + host).pipe(map(data => {
	// 		let res: IintString = { id: 0, str: ''};
	// 		if (data) {
	// 			if(data.id > 0) { 
	// 				this.email.next(data.str); this.mlid = data.id; res.id = data.id; res.str = data.str; return res ; 
	// 			} else { this.email.next(''); this.error.next(res.str);  return res; }
	// 		} else { this.email.next(''); return res; }
	// 	}));
	// }
	mailid(): Observable<number> { // API: 1382 & SP: web.mailid
		if (this.mlid > 0) { return of(this.mlid); }
		else { 
			if(this.email.getValue()) {
				return this.cs.get(ApiUrls.web_mailid + '?email=' + this.email.getValue()).pipe(map(data => {
					if (data) { this.mlid = data!.id; return data!.id; } 
					else { return of(0) }
				}));
			} else { return of(0) }
		}
	}
 
	navitemsPaging( ia: boolean, params: PageParams): Observable<NavItem[]> {// API: 1381 & SP: web.navitemsPaging
		if (this.mlid <= 0) { return of([])}
		return this.cs.get(ApiUrls.web_navitemsPaging
		 + '?mlid=' + this.mlid
		 + (params.filt!.length > 0 ? '&filt=' + params.filt : '')
		 + (params.col ? '&col=' + params.col : '')
		 + '&dir=' + params.dir
		 + '&cp=' + params.cp
		 + '&ps=' + params.ps
		 + '&ia=' + ia).pipe(map(data => {
			if (data.Item1.length > 0 ) { this.totalNavitems = data.Item2.id; return data.Item1; } 
			else { this.totalNavitems = 0; return of([])}
		}));
	}
	getNestedMenu(menuData: NavItem[] ): NavItem[] {
		const xNavItems: NavItem[] = [];
		menuData.forEach((e: NavItem ) => {
			if (e.level === 1 ) {
				const item: NavItem = { id: e.id, par: e.par, displayName: e.displayName, disabled: e.disabled, 
					iconName: e.iconName, route: e.route, fullpath: e.fullpath, level: e.level };
				const childItems = this.getChildMenus(menuData, e.id!, e.level );
				if (childItems.length > 0) { item.children = childItems; } 
				xNavItems.push(item);
			}
		});
		return xNavItems;
	}
	getChildMenus(menuData: NavItem[], ID: number, level: number): NavItem[] {
		const menu: NavItem[] = [];
		const childrens = menuData.filter(r => r.par === ID);
		if (childrens.length > 0) {
			childrens.forEach((e: NavItem) => {
				if (e.level === level + 1) {
					const item: NavItem = { id: e.id, par: e.par, displayName: e.displayName, disabled: e.disabled, 
						iconName: e.iconName, route: e.route, fullpath: e.fullpath, level: e.level };
					const childItems = this.getChildMenus( menuData, e.id!, e.level );
					if (childItems.length > 0) { item.children = childItems;} 
					menu.push(item);
				}
			});
		}
		return menu;
	}
	getLeafNodes(nodes: NavItem[], result: NavItem[] = []): NavItem[]{
		for(var i = 0, length = nodes.length; i < length; i++){
			if(!nodes[i].children || nodes[i].children!.length === 0){
				result.push(nodes[i]);
			} else{ result = this.getLeafNodes(nodes[i].children!, result); }
		}
		return result;
	}
}

// private subjNavFlat = new BehaviorSubject<NavItem[]>([]) ;
// private subjNavHeir = new BehaviorSubject<NavItem[]>([])  ;
// navFlat$: Observable<NavItem[]> = this.subjNavFlat.asObservable();
// navHeir$: Observable<NavItem[]> = this.subjNavHeir.asObservable();
// getNavMenu(email: string): Observable<NavItem[]> { 
// 	const load$ = this.cs.get(ApiUrls.web_navitems + '?email=' + email).pipe(
// 		map((r: any) => {
// 			if (r.Item2.id < 0) { this.cs.warning('Error!'); this.subjNavFlat.next([]); return this.subjNavHeir.next([]); }
// 			return r.Item1;
// 		}),
// 		catchError(err => {  this.cs.warning('See console for Error!');  
// 		console.log(err); this.subjNavFlat.next([]); this.subjNavHeir.next([]); return []; }),
// 		finalize( () => console.log('MenuService.getNavMenu() finalize called') ),
// 		tap((Item1: any) => {
// 			if (Item1.length > 0) { 
// 				this.subjNavFlat.next(Item1);
// 				const subjs = this.getNestedMenu(Item1); 
// 				return this.subjNavHeir.next(subjs);
// 			} 
// 			else { this.subjNavFlat.next([]); return this.subjNavHeir.next([]); } 
// 		}), shareReplay());
// 	this.ldng.showLoaderUntilCompleted(load$).subscribe();
// 	return this.subjNavHeir;
// }
