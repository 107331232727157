export class ApiUrls{
	public static web_link = 'web/link';  
	public static web_mailid = 'web/mailid'; //1366 
	public static web_navitemDDL = 'web/navitemDDL'; //1366 
	public static web_navitems = 'web/navitems'; // 1364
	public static web_navitemsPaging = 'web/navitemsPaging'; // 1381
	public static web_updateOrder = 'web/updateOrder'; // 1381
	
	public static web_navitemAdd = 'web/navitem-add'; // 1365
	public static web_navitemEdit = 'web/navitem-edit'; // 1367
	public static web_navitemDel = 'web/navitemDel'; // 1368
	
	public static web_titlemeta = 'web/titlemeta'; // 1369
	public static web_pageAdd = 'web/pageAdd'; // 1370
	public static web_metaAdd = 'web/metaAdd'; // 1371
	public static web_titleEdit = 'web/titleEdit';//1372
	public static web_metaEdit = 'web/metaEdit'; // 1373
	public static web_metanameDDL = 'web/metanameDDL'; // 1380
	public static web_iconDDL = 'web/iconDDL';  
	public static web_stdheaderDDL = 'web/stdheaderDDL';  

	public static web_featureEdit = 'web/featureEdit';
	public static web_featureAdd = 'web/featureAdd';
	public static web_features = 'web/features';


	public static web_sections = 'web/sections';
	public static web_sections2 = 'web/sections2';
	public static web_sectionAdd = 'web/sectionAdd';
	public static web_sectionEdit = 'web/sectionEdit';
	
public static web_paras = 'web/paras';
public static web_paras2 = 'web/paras2';
public static web_paraAdd = 'web/paraAdd';
public static web_paraEdit = 'web/paraEdit';
public static web_updateStatus = 'web/updateStatus';
public static web_deleteRows = 'web/deleteRows';

public static web_headersAdd = 'web/headersAdd';
public static web_headersEdit = 'web/headersEdit';
public static web_headers = 'web/headers';


	public static Pages_ReadPages = 'Pages/ReadPages';
	public static Pages_ReadDelPages = 'Pages/ReadDelPages';
	public static Pages_CreatePages = 'Pages/CreatePages';
	public static Pages_UpdatePages = 'Pages/UpdatePages';
	public static Pages_DeletePages = 'Pages/DeletePages';
	public static Pages_RestorePages = 'Pages/RestorePages';
	public static Pages_pagesDDL =  'Pages/pagesDDL';
	public static Pages_menuDDL = 'Pages/menuDDL';
	public static API2Menu_noCmdMenuDDL = 'API2Menu/noCmdMenuDDL';
	public static API2Menu_cmdMenuDDL = 'API2Menu/cmdMenuDDL';

	public static Pages_modDDL = 'Pages/modDDL';
	public static menu_menuTree = 'menu/menuTree';
	public static menu_heir = 'menu/heir';

	public static HomeRoute = '/hms/hms-home';
	public static LoginRoute = '/login';
	public static RegisterSuccessRoute = '/register-success';
	public static ResetPasswordRoute = 'reset-password';
	
	public static LoginUrl = 'Account/Login';
	public static ForgotPassword = 'account/forgotPassword';
	public static Register = 'account/register';
	public static menu_checkin = 'menu/checkin'; // previous url >> company-mid
	
	public static GetCompanyList = 'login/GetCompanyList';
	// public static resetPassword = 'account/ResetPassword';

	public static ChangePassword = 'account/ChangePassword';

	public static Account_IsUserExist = 'account/IsUserExist';
	public static Account_RegisterExternal = 'account/RegisterExternal';
	public static Account_LoginExternal = 'account/LoginExternal';
	public static Account_googleSignin = 'account/googleSignin';

	public static login_loginDetails = 'login/loginDetails';
	public static AutoAPI_formModelData = 'AutoAPI/formModelData';
	public static file_uploadThumbnail = 'file/uploadThumbnail'; 
	public static file_uploadBulk = 'file/uploadBulk';
	public static file_azUpload = 'file/azUpload'; 


}
