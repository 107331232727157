import { ChangeDetectionStrategy, Component, Input} from '@angular/core';
import { environment } from 'src/environments/environment';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';

@Component({
	standalone: true,
	selector: 'sa-header-image',
	imports: [ CommonModule, NgOptimizedImage, MatCardModule, MatIconModule, MatDividerModule],
	template: `
		<div class="image-props" [ngStyle]=" {'background': 'linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ) , url('
		 + storagePath + imgsrc + ')'	, 'height': imght + 'px' } "> 
			<div class="box">
					<div class="mat-display-3">{{mdtext}} <mat-divider></mat-divider> </div>	
					<div class="mat-headline">{{mhtext}}</div>
			</div>
		</div>
	` , changeDetection: ChangeDetectionStrategy.OnPush
	, styles: [`
		.image-props{
		  display: list-item; /* background-color: #000000; Used if the image is unavailable   'background-image': 'url(' + imgsrc + ')'   */
			background-position: center top; /* Center the image */
			background-repeat: no-repeat; /* Do not repeat the image */
			background-size: cover; /* Resize the background image to cover the entire container */
			transform: scale(1.00); opacity: 1.0; width: 100vw;
			/* transition: opacity 0.4s, transform 0.4s; */
		}:hover { transform: scale(1.10); opacity: 0.7; transition: opacity 0.4s, transform 0.4s; }
		.box{ display: flex; height: inherit; align-items: center; justify-content: center; flex-direction: column; }
		.box > div {  color: white; }
		/* this is NOT working */
		
	  
	`]
})

export class SaHeaderImageComponent {
	@Input() mdtext = ''; // 'Accounts'
	@Input() mhtext = ''; // 'Integrated finance and accounting system'
	@Input() imgsrc = ''; // 'https://sixdstorage.blob.core.windows.net/fileserver/WEB/web.header/imgsrc/591.webp';
	@Input() imght = 500;
	storagePath = environment.fileServer_url + '/' + environment.container + '/' + 'WEB/web.header/imgsrc/'; 
}
