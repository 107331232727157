// example.service.ts
import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';


// READ THIS https://github.com/angular/universal/blob/main/docs/gotchas.md
// GOOD INFO https://stackoverflow.com/questions/34177221/how-to-inject-window-into-a-service


@Injectable({providedIn: 'root'})
export class GlobalService {
  constructor(@Inject(DOCUMENT) private _doc: Document) {}

  getWindow(): Window | null { return this._doc.defaultView; }

  getLocation(): Location {
    return this._doc.location;
  }

  createElement(tag: string): HTMLElement {
    return this._doc.createElement(tag);
  }
}


// usage
/*
import { GlobalService } from './services/global.service';
, private gbl: GlobalService
window = this.gbl.getWindow()!
*/
