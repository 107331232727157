import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { IFeature, IHeaders, IParas, ISection, ITitlemeta, NavItem } from "src/app/x-common/models";
import { MenuService } from "../services/menu.service";
import { LiveServices } from "../services/live.service";

@Injectable({providedIn: 'root'})

export class PageResolver { // implements Resolve<ISection[]>{
	
	private page = new BehaviorSubject<NavItem|null>(null);
	private sections2 = new BehaviorSubject<ISection[]>([]);
	private paras = new BehaviorSubject<IParas[]>([]);
	private features = new BehaviorSubject<IFeature[]>([]);
	private headers = new BehaviorSubject<IHeaders[]>([]);
	private ttlmeta = new BehaviorSubject<ITitlemeta[]>([]);

	page$ = this.page.asObservable();
	sections$ = this.sections2.asObservable();
	paras$ = this.paras.asObservable();
	features$ = this.features.asObservable();
	headers$ = this.headers.asObservable();
	ttlmeta$ = this.ttlmeta.asObservable();
	csvids = ''
	constructor(private live: LiveServices, private ms: MenuService ) { }

	getBodyData(page: NavItem): Promise<any> {
		this.page.next(page!);
		const menuid = page.id!; 
		const p2 = new Promise<boolean>((resolve) => { this.live.sections2(menuid).subscribe(res => { this.sections2.next(res);  resolve(true)}); })
		const p3 = new Promise<boolean>((resolve) => { this.live.features(menuid).subscribe(res => { this.features.next(res);  resolve(true)}); })
		const p4 = new Promise<boolean>((resolve) => { this.live.headers(menuid).subscribe(res => { this.headers.next(res); resolve(true)}); })
		const p5 = new Promise<boolean>((resolve) => { this.live.titlemeta(menuid).subscribe(res => { this.ttlmeta.next(res); resolve(true)}); })
		const p = Promise.all([ p2, p3, p4, p5]).then((v) =>  v)
		return p;
	}
	

}
	// let newSections: ISection[] = []; let newParas: IParas[] = [];
	// const path = this.ms.navItemsFlat.find(x => x.id === menuid)!.fullpath ?? ''; 
	// const pg = this.ms.navItemsFlat.find(x => x.id === menuid);

	// this.sections2.next([]); this.paras.next([]); this.features.next([]); this.headers.next([]);
	// const p1 = new Promise<boolean>((resolve) => { this.live.paras2(menuid).subscribe(res => { this.paras.next(res); newParas = res; resolve(true)}); })
	// const p2 = new Promise<boolean>((resolve) => { 
	// 	p1.then(v => {
	// 		if(newParas.length > 0) { 
	// 			this.live.sections(menuid).subscribe(res => { 
	// 			newSections = res; 
	// 			newSections.map( s => { 
	// 				s.paras! = newParas.filter(f => f.scid === s.id).map(x => x.para); 
	// 			}); 
	// 			this.sections2.next(newSections); 
	// 			newSections = []; newParas = []; resolve(true)
	// 		}); } 
	// 		else { this.live.sections(menuid).subscribe(res => { this.sections2.next(res); }) }
	// 	})
	// })

	// if (csvids) {
	// 	this.live.paras(csvids).subscribe(prs => {
	// 		if(prs.length !== 0) {// const paras = prs;
	// 			ss.map( s => { 
	// 				s.paras! = prs.filter(f => f.scid === s.id).map(x => x.para); 
	// 			});
	// 		} 
	// 		return this.sections.next(ss);
	// 	});
	// }
