import {Component, Input, ViewChild } from '@angular/core';
import {Router} from '@angular/router';
import { NavItem } from './models';
// https://stackblitz.com/edit/dynamic-nested-topnav-menu-wzrzea?file=app%2Fapp.component.ts
@Component({
  selector: 'top-menu-item',
  template:  `
	<mat-menu #childMenu="matMenu" [overlapTrigger]="false">
		<span *ngFor="let child of items">
			<!-- Handle branch node menu items -->
			<span *ngIf="child.children && child.children.length > 0" class="box">
				<button mat-menu-item [matMenuTriggerFor]="menu.childMenu" id="child.id" type="menu" >
					<mat-icon>{{child.iconName}}</mat-icon>
					<span >{{child.displayName}}</span>
				</button>
				<top-menu-item #menu [items]="child.children"></top-menu-item>
			</span>
			<!-- Handle leaf node menu items -->
			<span *ngIf="!child.children || child.children.length === 0">
				<!-- <button mat-menu-item [routerLink]="child.fullpath" id="child.id" type="menu"> -->
				<a style="display: block;" [href]="'#/' + child.fullpath" id="child.id" type="menu" mat-button >
					<span class="box">
						<mat-icon>{{child.iconName}}</mat-icon>
						{{child.displayName}}
					</span>
				</a>
			</span>
		</span>
	</mat-menu>
  `, styles: [`
		.mat-icon{transform: scale(0.75); }
		.box{display: flex; flex-wrap: nowrap; align-items: center; justify-content: left; font-size: 16px; }
		/* style="display: flex; font-size: 16px; align-items: center; " */
		/* button.mat-menu-item:hover { background-color: lightGreen; color: #fff !important; } */
		/* button styles are not working here; rather they work in app.component where these are called
		*/
  `]
})
export class TopMenuItemComponent {
  @Input() items!: NavItem[];
  // with { static: true } the ERROR GONE !!! >> Expression has changed after it was checked. Previous value: 'undefined'. Current value: '[object Object]'
  @ViewChild('childMenu', { static: true }) public childMenu: any;
  constructor(public router: Router) { }
}
