import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-page-not-found',
	template: `
		<!-- <div class="mat-display-4" style="margin: 200px;">
			<span style="background-color: violet; border: solid black 2px; border-radius: 20px;" > 4O4 </span> 
			<div class="mat-display-3" style="padding-top: 50px"> page-not-found!  
			 
				<div></div>
				<a class="mat-display-6" style="padding: 20px; " [href]="'#/home/index'">
					<h1>click here to navigate to Home page</h1>
				</a> 
			</div>
		</div> -->
		<div  style="display: flex; height: 100%; justify-content: center;">
			<div class="not-found">
				<img class="course-image mat-elevation-z10" src="../../assets/images/404.webp">
				<div style="margin-left: 10px;">
					<h1>Page Not Found</h1>
					<p>Either page not yet implemented or mistype something in the browser address bar?</p>
					<a [href]="'#/home/index'"> <h1>click here to navigate to Home page</h1> </a> 
					</div>
				</div>
		</div>
	`,
	styles: [`
			.course-image { max-width: 300px; border-radius: 4px; }
			.not-found { padding: 40px; display: flex; flex-direction: column; font-family: "Roboto"; }
			.about button { max-width: 100px; margin-top: 30px; }
	` ]
})
export class PageNotFoundComponent {

}
