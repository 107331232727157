import {ChangeDetectionStrategy, Component, HostBinding, Input, NgZone, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import { NavService } from '../services/nav.service';

import {animate, state, style, transition, trigger} from '@angular/animations';
import { NavItem } from './models';
// https://stackblitz.com/edit/dynamic-nested-sidenav-menu-duubjg?file=app%2Fapp.component.ts
// 
@Component({
  selector: 'side-menu-item',
  template: `
  <div mat-list-item [ngStyle]="{'padding-left': (depth * 12) + 'px'}" (click)="onItemSelected(item)"
    [ngClass]="{'active': item.fullpath ? router.isActive(item.fullpath, true): false, 'expanded': expanded}"
    class="menu-list-item">
    <a *ngIf="isLeaf" [href]="'#/' + item.fullpath"></a>
    <span > <mat-icon class="routeIcon">{{item.iconName}}</mat-icon> {{item.displayName}} </span>
    <span *ngIf="item.children && item.children.length">
      <span ><mat-icon [@indicatorRotate]="expanded ? 'expanded': 'collapsed'"> expand_more </mat-icon></span>
    </span>

  </div>
  <div *ngIf="expanded" class="box">
	  <side-menu-item *ngFor="let child of item.children" [item]="child" [depth]="depth+1"> </side-menu-item>
	</div>
  `,
  styleUrls: ['./side-nav.scss'],
  animations: [
    trigger('indicatorRotate', [
      state('collapsed', style({transform: 'rotate(0deg)'})),
      state('expanded', style({transform: 'rotate(180deg)'})),
      transition('expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4,0.0,0.2,1)')
      ),
    ])
  ],
	changeDetection: ChangeDetectionStrategy.OnPush

})
export class MenuListItemComponent implements OnInit {
  expanded = false;
  @HostBinding('attr.aria-expanded') ariaExpanded = this.expanded;
  @Input() item!: NavItem;
  @Input() depth!: number;
  isLeaf = false;
  constructor(public navService: NavService, public router: Router, private _ngZone: NgZone ) { 
    if (this.depth === undefined) { this.depth = 0; }
  }

  ngOnInit() {
    this.navService.currentUrl.subscribe((url: string) => {
      if (this.item!.fullpath && url) {
        // console.log(`Checking '/${this.item.fullpath}' against '${url}'`);
        this.expanded = url.indexOf(`/${this.item.fullpath}`) === 0;
        this.ariaExpanded = this.expanded;
        // console.log(`${this.item.fullpath} is expanded: ${this.expanded}`);
      }
    });
  }

  onItemSelected(item: NavItem) {
    if (!item.children || !item.children.length) {
      this._ngZone.run(() => { this.router.navigate([item.fullpath]); });
      this.isLeaf = true; // this.router.navigate([item.fullpath]);
      this.navService.closeNav();
    } else {
      this.expanded = !this.expanded;
      this.isLeaf = false; 
    }
  }
}
