import { NgModule, CUSTOM_ELEMENTS_SCHEMA, Inject, PLATFORM_ID, APP_ID, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { ServiceWorkerModule, SwRegistrationOptions } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialExampleModule } from './material.module'
import { SharedModule } from './shared/shared.module';
import { MenuListItemComponent } from './x-common/side-menu-item';
import { Routes, RouterModule, ExtraOptions, UrlSerializer } from '@angular/router';
import { TopMenuItemComponent } from './x-common/top-menu-item';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { LoadingComponent } from './x-common/loading.component';
import { LoadingService } from './services/loading.service';
import { MessagesService } from './services/messages.service';
import { LessonComponent, SearchLessonsComponent } from './x-common/search-results';
import { AuInputModule } from './lib/input/sixd-input.module';
import { AuTabPanelModule } from './lib/tabs/tab.module';
import { MessagesComponent } from './x-common/message.comonent';
import { NewsService } from './services/news.service';
import { isPlatformBrowser } from '@angular/common';
import { QuillModule } from 'ngx-quill';
import { AnyPageComponent } from './x-content/any-page.component';
import { ChatComponent } from './x-common/chat';
import { HttpTokenInterceptor } from './services/http.token.interceptor';
import { CanLoadAuthGuard, CustomPreloadingStrategy } from './shared/directives/auth.guard';
import { HomeIndexComponent } from './home/home-index.component';

// import { NgxYoutubePlayer14Module } from 'ngx-youtube-player-14';
 
// import Counter from './counter';
// import { OnlineStatusComponent } from './xcommon/online-status';

// follow this simple >> https://nested-lazy-loaded-routing.stackblitz.io
const routes: Routes = [ 
	
	{path: '', redirectTo: 'home/index', pathMatch: 'full'}, 
	{ path: 'home/index', component: HomeIndexComponent },
	{ path: 'account', loadChildren: () => import('./account/account.module').then(m => m.AccountModule)
		, data: { preload: false } },
	{ path: 'samples', loadChildren: () => import('./samples/sample.module').then(m => m.SampleModule)
		, data: { preload: false } },
	{ path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
		, data: { preload: false } },
	{ path: 'helpdesk-chat', component: ChatComponent, outlet: 'chat'},
];

// https://angular-university.io/lesson/angular-router-extra-options-recommended-settings
const config: ExtraOptions = {
	useHash: true
	, scrollPositionRestoration: 'enabled'
	, paramsInheritanceStrategy: 'always'
	, malformedUriErrorHandler: (error, urlSerializer: UrlSerializer, url) => urlSerializer.parse('app-page-not-found')
	, enableTracing: false // Turn this on to log routing events to the console
	, preloadingStrategy: CustomPreloadingStrategy
	, relativeLinkResolution: 'corrected',
};
@NgModule({
	declarations: [
		AppComponent, MenuListItemComponent, TopMenuItemComponent, LoadingComponent, MessagesComponent
		, LessonComponent, SearchLessonsComponent, AnyPageComponent, ChatComponent 
		//, OnlineStatusComponent 
	],
	imports: [
		RouterModule.forRoot(routes, config)// AppRoutingModule,  
		, MaterialExampleModule
		, BrowserModule.withServerTransition({ appId: 'sixdwsAppId' })
		, BrowserAnimationsModule, FormsModule, ReactiveFormsModule 
		, ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production })
			/* 	Register the ServiceWorker as soon as the application is stable
			 	or after 30 seconds (whichever comes first).
			 	registrationStrategy: 'registerWhenStable:30000'
		 	})*/
		// https://stackblitz.com/edit/angular12-spinner?file=src%2Fapp%2Fapp.component.html
		// , NgxSpinnerModule.forRoot({ type: 'ball-scale-multiple' })
		, SharedModule
		, HttpClientModule, ToastrModule.forRoot()
		, AuInputModule, AuTabPanelModule
		, QuillModule.forRoot({
      // customModules: [{ implementation: Counter, path: 'modules/counter' }],
			suppressGlobalRegisterWarning: true,
      customOptions: [{ import: 'formats/font', whitelist: ['mirza', 'roboto', 'aref', 'serif', 'sansserif', 'monospace'] }]
    }) // , NgxYoutubePlayer14Module.forRoot()
	],
	providers: [
		{ provide: LOCALE_ID, useValue: 'en-US' }
		// , { provide: HTTP_INTERCEPTORS, useClass: HttpTokenInterceptor, multi: true }
		, {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {appearance: 'fill', floatLabel: 'always'}}
		// , {provide: MAT_DATE_LOCALE, useValue: 'en-GB'} 
		// , {provide: DateAdapter, useClass: MomentDateAdapter,
		// 		deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
		// 	}
		// ,{provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS}
		,{ provide: SwRegistrationOptions, useFactory: () => ({ enabled: environment.production }), }
		, LoadingService, MessagesService, NewsService
		, CanLoadAuthGuard , CustomPreloadingStrategy
		 
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	bootstrap: [AppComponent],
})
export class AppModule { 
	// see this >> for below code
	// https://github.com/KillerCodeMonkey/ngx-quill-universal-example/blob/4c5318a0a83585f256b4f62dd4375ab95a705b2a/src/app/app.module.ts
	constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(APP_ID) private appId: string
  ) {
    const platform = isPlatformBrowser(platformId) ? 'in the browser' : 'on the server';
    console.log(`Running ${platform} with appId=${appId}`, platformId);
  }
}
