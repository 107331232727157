import { Component, Inject, Input, NgZone, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router'; // , Router 
import { Subject } from 'rxjs'; // forkJoin, 
import { DdlService } from '../services/ddl.service';
import { MenuService } from '../services/menu.service';
import { PageResolver } from './page.resolver';
// import { LiveServices } from '../services/live.service';
import { ITitlemeta, NavItem } from '../x-common/models';
import { isPlatformBrowser } from '@angular/common'; // DOCUMENT, 
// import { WindowSizeService } from '../services/window-size-service';
import { MessagesService } from '../services/messages.service';
@Component({
	selector: 'any-page',
	template: `
		<div class="box">
			<div *ngIf="(resolved && (rslvr.headers$ | async)) as headers">
				<div *ngIf="headers.length>0  && isBrowser">  <!--else defHdr -->
					<!-- <div *ngIf="fullpath === 'home/index'; else others">
						<header-3d [header]="headers[0].mdtext ?? 'HOME'" [HEIGHT]="headers[0].imght ?? 400"> </header-3d>
						<bg-video></bg-video>
					</div> -->
					<!-- <ng-template #others> -->
						<div *ngIf="headers[0].imgsrc" style="column-count: 1;">
								<header-image [imgsrc]="headers[0].imgsrc!" [imght]="headers[0].imght!"
								[mdtext]="headers[0].mdtext!" [mhtext]="headers[0].mhtext!" ></header-image>
						</div>
					<!--  </ng-template> -->
					<div style="display: block; height: 50px width: 100%"> <goto></goto></div>
				</div>
				<!-- <ng-template #defHdr> 
						<div *ngIf="page"> <header-3d [header]="page!.displayName" [HEIGHT]="300"></header-3d> </div>
				</ng-template> -->
			</div>
		<div id="down"></div> 
		<mat-divider></mat-divider>

		<ng-template ngFor let-item [ngForOf]="rslvr.sections$ | async" let-i="index">
				<sixd-float [imgsrc]="item.imgsrc!" [imgalt]="item.imgalt!" [wImg]="item.wdpc ?? 20"  [hImg]="item.htpc ?? 20"
				[header2]="item.header2!" [paras] = "item.paras!" [isImgFirst]="true" ></sixd-float>
		</ng-template>

		<div *ngIf="this.fullpath==='home/clients'"> <span [innerHTML]="imagelist"></span> </div>
		<div *ngIf="rslvr.features$ | async as features">
				<div class="grid">
				<div *ngFor="let ft of(rslvr.features$ | async) ">
					<sixd-card [title]="ft.title!" [subtitle]="ft.subtitle!" [icon]="ft.icon!"
					[isHeaderIcon]="false" [more]="ft.content!.length > 0" [link]="true" [id]="ft.id!" [content]="ft.content!"></sixd-card>
				</div>
			</div>
		</div>
		<!-- <div [ngStyle]="{'width': w+'px', 'height': h+'px' }">
			<sixd-youtube [videoId]="videoId" [w]="w" [h]="h" ></sixd-youtube>
		</div> -->
			<!-- <youtube-api [videoId]="videoId" [w]="w" [h]="h"></youtube-api> -->
	</div>
	` ,
	 styles: [`
		.box { display: flex; flex-direction: column; }
		.grid { display: grid; 
			grid-template-columns: repeat(4, minmax(100px, 1fr)); 
			grid-auto-rows: minmax(100px, auto);  
			gap: 20px; grid-gap: 20px; }
	`]
})
export class AnyPageComponent implements OnInit, OnDestroy { 
	page!: NavItem;
	fullpath = '';
	imagelist = images;
	@Input() gridColumns = 3;
	videoId = 'i_NN60XP9m0' // 'https://www.youtube.com/embed/i_NN60XP9m0' // 
	w = 560; h = 315;
	resolved = false;
	isBrowser: any;
	loginurl = '/account/login';
	// , private _ngZone: NgZone, private live: LiveServices, @Inject(DOCUMENT) private _doc: Document, private router: Router
	constructor(  private route: ActivatedRoute, public rslvr: PageResolver, public msg: MessagesService
	, private ms: MenuService, private ddls: DdlService, @Inject(PLATFORM_ID) private platformId: Object
	, private title: Title, private meta: Meta ) { this.isBrowser = isPlatformBrowser(platformId); }
	
	ngOnInit(): void { 
		this.ms.routconfig$.subscribe((tf) => { 
			if(!tf) { return; }
			this.fullpath = this.route.snapshot.routeConfig!.path!;
			this.page = this.ms.navItemsFlat.find(x => x.fullpath === this.fullpath)!
			const menuid = this.page ? ( this.page.id ? this.page.id : 0 )  : 0; 
			if(menuid===0) { return }
			this.ddls.metanameDDL().subscribe((metaddl) => { 
				const types = metaddl;
				this.rslvr.getBodyData(this.page).then(v =>{ 
					this.resolved = true;
					if(v[3]) {
						this.rslvr.ttlmeta$.subscribe((ttlmeta: ITitlemeta[]) => {
							if(ttlmeta.length === 0) { return }
							this.title.setTitle(ttlmeta[0]!.title);
							this.removeTags()
							ttlmeta.forEach(x => {
								const name = metaddl.find(r => r.id === x.nmid)?.str;
								this.meta.addTag({ name: name ?? 'description', content: x.content })
							});
					});
					}
				});
			});
		})
		
	}
	removeTags(){
		this.ddls.metaname.forEach(e => {
			const tag = this.meta.getTag('name=' + e.str);
			if (tag) this.meta.removeTagElement(tag);
		})
	}
	protected _onDestroy = new Subject<void>();
	ngOnDestroy(): any { this._onDestroy.next(); this._onDestroy.complete(); }
	 
}

const images = `
	<div class="owl-carousel clients-carousel">
			<img width="120" height="101" alt="AjayDeep Constructions" src="../../assets/clients/AjayDeep.webp" />
			<img width="120" height="101" alt="AMR Constructions" src="../../assets/clients/AMR.webp" />
			<img width="120" height="101" alt="Atlanta Constructions" src="../../assets/clients/Atlanta.webp" />
			<img width="120" height="101" alt="CBIT Engineering College" src="../../assets/clients/CBIT.webp" />
			<img width="120" height="101" alt="central-soil-metiral lab" src="../../assets/clients/central-soil-metiral.webp" />
			<img width="120" height="101" alt="Central Water Commision" src="../../assets/clients/CentralWaterCommision.webp" />
			<img width="120" height="101" alt="Galacon Constructions" src="../../assets/clients/Galacon.webp" />
			<!-- width="120" height="101" <img alt="Gayatri Projects" src="../../assets/clients/Gayatri.webp" /> -->
			<img width="120" height="101" alt="GVR Constructions" src="../../assets/clients/GVR.webp" />
			<img width="120" height="101" alt="ITNL Constructions" src="../../assets/clients/itnl_logo.webp" />
			<img width="120" height="101" alt="IVRCL Infrastructure" src="../../assets/clients/IVRCL.webp" />
			<img width="120" height="101" alt="Ketan Constructions" src="../../assets/clients/KCL.webp" />
			<img width="120" height="101" alt="KITS Constructions" src="../../assets/clients/KITS.webp" />
			<img width="120" height="101" alt="KNR Constructions" src="../../assets/clients/KNRConstructions.webp" />
			<img width="120" height="101" alt="KRRINFRA" src="../../assets/clients/KRRINFRA.webp" />
			<img width="120" height="101" alt="Military Engineering Services" src="../../assets/clients/mes_hyd.webp" />
			<img width="120" height="101" alt="MIT" src="../../assets/clients/MIT.webp" />
			<img width="120" height="101" alt="NAC" src="../../assets/clients/NAC.webp" />
			<!-- width="120" height="101" <img alt=" Constructions" src="../../assets/clients/NCC.webp" /> -->
			<img width="120" height="101" alt="NICMAR" src="../../assets/clients/NICMAR.webp" />
			<img width="120" height="101" alt="Nitsurthi Constructions" src="../../assets/clients/Nitsurthi.webp" />
			<img width="120" height="101" alt="NITW Constructions" src="../../assets/clients/NITW.webp" />
			<img width="120" height="101" alt="Rithwik Constructions" src="../../assets/clients/Rithwik.webp" />
			<img width="120" height="101" alt="SEW Constructions" src="../../assets/clients/SEW.webp" />
			<img width="120" height="101" alt="SHAILIPARDI Constructions" src="../../assets/clients/SHAILIPARDI.webp" />
			<img width="120" height="101" alt="SVEC Constructions" src="../../assets/clients/SVEC.webp" />
			<img width="120" height="101" alt="TTD" src="../../assets/clients/TTD.webp" />
			<img width="120" height="101" alt="Vishal Infra" src="../../assets/clients/VishalInfra.webp" />
			<img width="120" height="101" alt="vr associates" src="../../assets/clients/vrassociates.webp" />
			<!-- <img alt=" Constructions" src="../../assets/clients/SedarConstructions.webp" /> -->
			<!-- <img alt=" Constructions" src="../../assets/clients/SSK.webp" /> -->
	</div>
`
	// this.ms.email$.subscribe((email) => { 
		// 	if(email) {
		// 		this.fullpath = this.route.snapshot.routeConfig!.path!;  //	console.log(this.fullpath);
		// 		if(this.ms.navItems.length > 0) { this.load(); }
		// 		else { 
		// 			this.ms.error$.subscribe((e)=> {
		// 				if(e.length > 0) { this.msg.showErrors(e) } 
		// 				else { 
		// 					this.ms.webNavitems(email).subscribe((res: NavItem[]) => { debugger;
		// 						if( res.length > 0){ this.load(); }
		// 						else { this.msg.showErrors('Development menu not be loaded') }
		// 					});
		// 				}
		// 			});
		// 		}
		// 	} else { 
		// 		this.msg.showErrors('Production menu from the website could not be loaded');
		// 		// if(environment.production) {
		// 		// 	this.msg.showErrors('Production menu from the website could not be loaded');
		// 		// } else {
		// 		// 	this._ngZone.run(() => { this.router.navigate([this.loginurl]); }); 
		// 		// }
		// 	}
	// });
