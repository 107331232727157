import { Injectable } from '@angular/core';
import { map, Observable, of } from 'rxjs';
import { IintString } from 'src/app/x-common/models';
import { ApiUrls } from './api-urls';
import { CoreService } from './core.service';

@Injectable({ providedIn: 'root' })

export class DdlService {
	// public navitem: IintString[] = [];
	public metaname: IintString[] = [];
	public icons: IintString[] = [];
	public stdHeaders: IintString[] = [];
	constructor(public cs: CoreService ) {}
	// navitemDDL(email: string): Observable<IintString[]> { // 1366
	// 	if (this.navitem.length > 0) { return of(this.navitem); }
	// 	return this.cs.get(ApiUrls.web_navitemDDL + '?email=' + email ).pipe(map(data => {
	// 		this.navitem = data; return this.navitem;
	// 	}));
	// }
	metanameDDL(): Observable<IintString[]> { // 1380
		if (this.metaname.length > 0) { return of(this.metaname); }
		return this.cs.get(ApiUrls.web_metanameDDL).pipe(map(data => {
			this.metaname = data; return this.metaname;
		}));
	}
	iconDDL(): Observable<IintString[]> { 
		if (this.icons.length > 0) { return of(this.icons); }
		return this.cs.get(ApiUrls.web_iconDDL).pipe(map(data => {
			this.icons = data; return this.icons;
		}));
	}
	stdheaderDDL(): Observable<IintString[]> { 
		if (this.stdHeaders.length > 0) { return of(this.stdHeaders); }
		return this.cs.get(ApiUrls.web_stdheaderDDL).pipe(map(data => {
			this.stdHeaders = data; return this.stdHeaders;
		}));
	}
}
