import { Component, Input, OnInit } from '@angular/core';
import { NavigationStart, Router, RouteConfigLoadStart, NavigationEnd, NavigationCancel, RouteConfigLoadEnd } from '@angular/router';
import { LoadingService } from '../services/loading.service';
 
// https://angular-university.io/lesson/reactive-loading-indicator-finished
@Component({
  selector: 'loading',
  template: `
    <div class="spinner-container" *ngIf="loadingService.loading$ | async"> 
      <mat-spinner></mat-spinner> 
    </div>
  ` 
})

export class LoadingComponent implements OnInit {
  @Input() detectRoutingOngoing = false;

  constructor(public loadingService: LoadingService, private router: Router) {
    // console.log('reached here');
  } 
  ngOnInit(): void {
    if (this.detectRoutingOngoing) {
      this.router.events.subscribe((e: any) => {
        if(e instanceof NavigationStart || e instanceof RouteConfigLoadStart) {
          this.loadingService.loadingOn()
        } else if(e instanceof NavigationEnd || e instanceof NavigationCancel || e instanceof RouteConfigLoadEnd){
          this.loadingService.loadingOff()
        }
      });
    }
  }
}


