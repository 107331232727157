import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import {tap} from 'rxjs/operators';
import { MessagesService } from '../services/messages.service';

@Component({
  selector: 'messages',
  template: `
	<ng-container *ngIf="(errors$ | async) as errors">
		<div class="messages-container" *ngIf="showMessages">
				<div *ngFor="let error of errors">
						{{error}}
				</div>
				<mat-icon class="msg-close" (click)="onClose()">close</mat-icon>
		</div>
	</ng-container>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MessagesComponent implements OnInit {
  showMessages = false;
  errors$!: Observable<string[]>;
  constructor(public messagesService: MessagesService) {
      // console.log("Created messages component");
  }
  ngOnInit() {
      this.errors$ = this.messagesService.errors$
          .pipe( tap(() => this.showMessages = true) );
  }
  onClose() { this.showMessages = false; }

}
// usage
  // ctor declaration >>  , public msg: MessagesService
  // call where ver you need  this.msg.showErrors('this is from message Service');
