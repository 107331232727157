import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'header-image',
	template: `
		<div class="image-props" [ngStyle]=" {'background': 'linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ) , url('
		 + storagePath + imgsrc + ')'	, 'height': imght + 'px' } "> 
			<div class="box">
					<div class="mat-display-3">{{mdtext}} <mat-divider></mat-divider> </div>	
					<div class="mat-headline">{{mhtext}}</div>
			</div>
		</div>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush
	, styles: [`
		.image-props{
		  display: list-item; /* background-color: #000000; Used if the image is unavailable   'background-image': 'url(' + imgsrc + ')'   */
			background-position: center top; /* Center the image */
			background-repeat: no-repeat; /* Do not repeat the image */
			background-size: cover; /* Resize the background image to cover the entire container */
			transform: scale(1.00); opacity: 1.0; width: 100vw;
			/* transition: opacity 0.4s, transform 0.4s; */
		}:hover { transform: scale(1.10); opacity: 0.7; transition: opacity 0.4s, transform 0.4s; }
		.box{ display: flex; height: inherit; align-items: center; justify-content: center; flex-direction: column; }
		.box > div {  color: white; }
		/* this is NOT working */
		
	  
	`]
})

export class HeaderImageComponent {
	@Input() mdtext = ''; // 'Accounts'
	@Input() mhtext = ''; // 'Integrated finance and accounting system'
	@Input() imgsrc = ''; // 'https://sixdstorage.blob.core.windows.net/fileserver/WEB/web.header/imgsrc/591.webp';
	@Input() imght = 500;
	storagePath = environment.fileServer_url + '/' + environment.container + '/' + 'WEB/web.header/imgsrc/'; 
}
 
// const THUMBUP_ICON = `
// 	<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px">
// 		<path d="M0 0h24v24H0z" fill="none"/>
// 		<path d="M1 21h4V9H1v12zm22-11c0-1.1-.9-2-2-2h-6.31l.95-4.57.03-.32c0-.41-.17-.79-.` +
// 	`44-1.06L14.17 1 7.59 7.59C7.22 7.95 7 8.45 7 9v10c0 1.1.9 2 2 2h9c.83 0 1.54-.5` +
// 	`1.84-1.22l3.02-7.05c.09-.23.14-.47.14-.73v-1.91l-.01-.01L23 10z"/>
// 	</svg>
// `;
const ARROW_DROPDOWN_CIRCLE = `
	<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48">
	<path d="m24 29.7 7.3-7.35H16.65ZM24 44q-4.1 0-7.75-1.575-3.65-1.575-6.375-4.3-2.725-2.725-4.3-6.375Q4 28.1 4 24q0-4.15 1.575-7.8 1.575-3.65 4.3-6.35 2.725-2.7 6.375-4.275Q19.9 4 24 4q4.15 0 7.8 1.575 3.65 1.575 6.35 4.275 2.7 2.7 4.275 6.35Q44 19.85 44 24q0 4.1-1.575 7.75-1.575 3.65-4.275 6.375t-6.35 4.3Q28.15 44 24 44Zm0-3q7.1 0 12.05-4.975Q41 31.05 41 24q0-7.1-4.95-12.05Q31.1 7 24 7q-7.05 0-12.025 4.95Q7 16.9 7 24q0 7.05 4.975 12.025Q16.95 41 24 41Zm0-17Z"/></svg>
`

@Component({
	selector: 'goto',
	template: `
	<div class="centered">
		<!-- <div class="mat-headline">{{mhtext}}</div> -->
		<button id="goto" aria-label="goto" mat-button onClick="document.getElementById('down').scrollIntoView({behavior: 'smooth'});"> 
		<mat-icon color="basic" style="width: 50px; height: 50px;" 
		svgIcon="arrow_dropdown_circle" ></mat-icon> </button>
	</div>
	`, styles: [`
		mat-icon:hover { color: red;}
	`]
})
export class GotoComponent implements OnDestroy {
	// @Input() mhtext = 'Goto' // Integrated finance and accounting system
	constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    // Note that we provide the icon here as a string literal here due to a limitation in
    // Stackblitz. If you want to provide the icon from a URL, you can use:
    // `iconRegistry.addSvgIcon('thumbs-up', sanitizer.bypassSecurityTrustResourceUrl('icon.svg'));`
    // iconRegistry.addSvgIconLiteral('thumbs-up', sanitizer.bypassSecurityTrustHtml(THUMBUP_ICON));
    iconRegistry.addSvgIconLiteral('arrow_dropdown_circle', sanitizer.bypassSecurityTrustHtml(ARROW_DROPDOWN_CIRCLE));
  }
	protected _onDestroy = new Subject<void>();
	ngOnDestroy(): any { this._onDestroy.next(); this._onDestroy.complete(); }
}
