 
import { Injectable, NgZone } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanDeactivate, CanLoad, PreloadingStrategy, Route, Router
	, RouterStateSnapshot, UrlSegment, UrlTree} from '@angular/router';
import { Observable, of} from 'rxjs';
import { first, map, tap} from 'rxjs/operators';
import { AdminPageComponent } from 'src/app/admin/admin-page';
import { AuthStore } from '../../services/auth.store';

// https://github.com/angular-university/angular-router-course/blob/e108853bae879c981f41ec0f7a9267b4175d6452/src/app/services/auth.guard.ts
@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate, CanActivateChild {
    constructor(private auth: AuthStore, private router:Router) { }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
        return this.checkIfAuthenticated();
    }
    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree>   {
        return this.checkIfAuthenticated();
    }
    private checkIfAuthenticated() {
        return this.auth.isLoggedIn$
            .pipe(
                map(loggedIn => loggedIn? true: this.router.parseUrl('/account/login') )
            );
    }
}
 
@Injectable({ providedIn: 'root' })
export class CanLoadAuthGuard implements CanLoad {
	constructor(private auth: AuthStore, private router: Router, private _ngZone: NgZone) { }
	canLoad(route: Route, segments: UrlSegment[]): Observable<boolean>  {
		return this.auth.isLoggedIn$
			.pipe( first(), tap(loggedIn => {
					if (!loggedIn) {
						this._ngZone.run(() => { this.router.navigate(['/account/login']); });
						// this.router.navigateByUrl('/account/login');
					}
				})
			);
	}
}

@Injectable({ providedIn: 'root' })
export class ConfirmExitGuard implements CanDeactivate<AdminPageComponent> {
    canDeactivate(component: AdminPageComponent, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot
			, nextState?: RouterStateSnapshot): any  {
        return component.confirmExit();
    }

}

@Injectable({ providedIn: 'root' })
export class CustomPreloadingStrategy implements PreloadingStrategy {
	preload(route: Route, load: () => Observable<any>): Observable<any> { // console.log(route?.data);
		if (route?.data && route.data["preload"]) { return load(); }
		else { return of(null); }
	}
}
