 
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SixdTabComponent } from './sixd-tab';
import { SixdTabPanelComponent } from './sixd-tab-panel';
@NgModule({
		declarations: [SixdTabPanelComponent, SixdTabComponent],
		imports: [ CommonModule ],
		exports: [SixdTabPanelComponent, SixdTabComponent]
})
export class AuTabPanelModule {
}
